import React, { useEffect, useState } from 'react';

import { formatDateTime as format } from "../../helpers/func";

import "./ewallet_Transactions.css";
import { Col, Row } from 'reactstrap';
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { url } from '../../store/initialStatus';
 
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Translate } from '../../helpers/Translate';


import Datetime from "react-datetime";
import { wrapper_post } from '../../helpers/call_wrapper_funcs';


export default function Transactions() {
    const t = new Translate()
    let receipt;
    interface Itransaction {
        CustomerTypeId: [],
        ItemQuantity: [],
        ItemCode: [],
        CheckDateTime: []
    }
    const [isModalOpen, ShowModal] = useState<boolean>(false);
    const toggle = () => ShowModal(!isModalOpen);

    let location = useLocation();
    const { id } = useParams();
    let Navigate = useNavigate();
    const [selectedStartDate, setSelectedStartDate] = useState(new Date());
    const [selectedEndDate, setSelectedEndDate] = useState(new Date());
    const [transactionOptions, settranscationOptions] = useState<{
        CheckDateTime: string, ItemCode: string,
        ItemEname: string, ItemQuantity: string, CustomerTypeId: string, CustomerId: string, CheckNumber: string, CheckTotal: string
    }[]>([]);

    const [transactionDetails, setTransactionDetails] = useState<any>([]);
    const [transactionData, settransactionData] = useState({
        CustomerTypeId: [''],
        ItemQuantity: [''],
        ItemCode: [''],
        CheckDateTime: [''],
        CustomerId: [''],
        CheckNumber: [''],
        CheckTotal: ['']

    });

    const getTransaction = () => {
        wrapper_post(`${url}payment/transactions.php`, { CustomerId: id, startDate: selectedStartDate, endDate: selectedEndDate }).then((res: any) => {
            receipt = res.data.data;
            const newTransationOptions = receipt?.map((transaction: any) => {
                return {
                    ...transaction,
                    CheckDateTime: transaction.CheckDateTime
                }
            });
            settranscationOptions(newTransationOptions);
        })
    }
    const getTransactionDetails = (checkNumber: any) => {
        wrapper_post(`${url}payment/gettransactionsdetails.php`, { CheckNumber: checkNumber }).then((res: any) => {
            receipt = res.data.data;
            setTransactionDetails(receipt);
        })
    }
    const filterByDate = (transactionOptions: any) => {
        transactionOptions.filter((transaction: any) => { return (transaction.CheckDateTime > selectedStartDate && transaction.CheckDateTime < selectedEndDate) })
    }
    useEffect(() => {
        getTransaction();
    }, []);

    const custumerTypeId = (string: any) => {
        switch (string) {
            case "3":
                return "E-wallet"
                break;
            case "4":
                return "Cash"
                break;
            default:
                return "Cash"

        }
    }



    return (
        <>
            <div>
                <div>
                    <button className="btn btn-primary" onClick={() => { Navigate('/dashboard/ewallet') }}>{t.translate("Back")}</button>
                </div>
                <div style={{ marginTop: "20px" }} >
                    <Row style={{ alignContent: "center" }} >
                        <Col> </Col>
                        <Col style={{ alignContent: "center" }}><h3>{t.translate("Filter Table By Date")}</h3></Col>

                        <Col>  </Col>
                    </Row>
                    <Row>

                        <Col style={{ marginTop: "20px" }}>
                            <label> From:</label>
                            <Datetime
                                value={selectedStartDate}
                                initialValue={new Date()}
                                dateFormat="MMMM Do YYYY"
                                onChange={(e: any) => setSelectedStartDate(e)}
                            />

                        </Col>
                        <Col style={{ marginTop: "20px" }}>
                            <label> to:</label>
                            <Datetime
                                value={selectedEndDate}
                                initialValue={new Date()}
                                dateFormat="MMMM Do YYYY"
                                onChange={(e: any) => setSelectedEndDate(e)}
                            />
                        </Col>
                        <Col style={{ marginTop: "20px" }}> 
                            <label> &nbsp;</label>
                            <button className='btn btn-primary d-block' onClick={()=>{getTransaction();}}> {t.translate("Apply Filter")}</button>
                        
                         </Col>
                    </Row>
                    <Row style={{ display: "flex", justifyItems: "space-evenly", marginTop: "20px" }}>
                        <Col md={4}><strong>{t.translate("Student Name")}:</strong> {location.state?.fullname}</Col>
                        <Col md={4}><strong> {t.translate("Student Id")}:</strong>  {location.state?.id}</Col>
                        <Col md={4}> <strong> {t.translate("Grade")}:</strong>  {location.state?.Grade}</Col>
                    </Row>

                </div>
                <div className="table-responsive">
                    <table className="table table-bordered">
                        <thead>
                            <tr >
                                <td style={{ justifyContent: "center" }}>
                                    <h4>{t.translate("Transaction Id")}</h4>
                                </td>
                                <td>
                                    <h4>{t.translate("Date")}</h4>
                                </td>
                                <td>
                                    <h4>{t.translate("Amount")}</h4>
                                </td>
                                <td>
                                    <h4>{t.translate("Transaction Type")}</h4>
                                </td>
                                <td>
                                    <h4>{t.translate("Actions")}</h4>
                                </td>

                            </tr>
                        </thead>
                        <tbody>
                            {transactionOptions?.map((transaction: any, i: any) => {
                                if (selectedStartDate.getTime() == selectedEndDate.getTime() || (new Date(transaction.CheckDateTime) >= selectedStartDate) && (new Date(transaction.CheckDateTime) <= selectedEndDate)) {
                                    return (
                                        <tr >
                                            <td>
                                                {`${transaction.CheckNumber}`}
                                            </td>
                                            <td>
                                                {format(Date.parse(transaction.CheckDateTime), "yyyy-MM-dd")}
                                                {/* {`${transaction.CheckDateTime}`} */}
                                            </td>
                                            <td>
                                                {`${transaction.CheckTotal}`}
                                            </td>
                                            <td>
                                                {custumerTypeId(`${transaction.PayWayCode}`)}
                                            </td>
                                            <td>

                                                <button onClick={() => {
                                                    toggle();
                                                    getTransactionDetails(transaction.CheckNumber)

                                                }} className="btn btn-primary"> {t.translate("View Details")}</button>
                                            </td>
                                        </tr>


                                    )


                                }
                            })

                            }


                        </tbody>

                    </table>
                </div>


            </div>
            <Modal style={{ marginTop: "200px" }} isOpen={isModalOpen} toggle={toggle} className="modal-xl">

                <ModalHeader style={{ alignItems: "center" }} toggle={toggle}>
                    {t.translate("Transaction Details")}
                </ModalHeader>
                <ModalBody>
                    <table className="table table-bordered">
                        <Row>
                            <Col xs={3}>
                                <strong>
                                    {t.translate("Item Code")}
                                </strong>
                            </Col>
                            <Col xs={3}>
                                <strong>
                                    {t.translate("Item name")}
                                </strong>
                            </Col>
                            <Col xs={3}>
                                <strong>
                                    {t.translate("Quantity")}
                                </strong>
                            </Col>
                            <Col xs={3}>
                                <strong>
                                    {t.translate("Item price")}
                                </strong>
                            </Col>
                        </Row>


                        {transactionDetails.map((transaction: any, i: any) => {
                            return (
                                <Row>
                                    <Col>
                                        <h5>
                                            {transaction.ItemCode}
                                        </h5>
                                    </Col>
                                    <Col>
                                        <h5>

                                            {transaction.ItemEname}
                                        </h5>
                                    </Col>
                                    <Col>
                                        <h5>
                                            {transaction.ItemQuantity}
                                        </h5>
                                    </Col>
                                    <Col>
                                        <h5>
                                            {`${transaction.SellingPrice} EGP`}
                                        </h5>
                                    </Col>
                                </Row>

                            )


                        })



                        }
                    </table>

                    <div ><strong style={{ width: "30px" }}> {t.translate("Total")} : </strong> {transactionDetails[0]?.CheckTotal} EGP</div>
                </ModalBody>
                <ModalFooter>
                    <button className="btn btn-primary" onClick={() => { ShowModal(false) }}>{t.translate("Done")}</button>
                </ModalFooter>
            </Modal>


        </>
    )
}


