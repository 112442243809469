import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
 
import { ModalBody, Modal, ModalHeader, ModalFooter, Form, Input, } from "reactstrap";
import { Translate } from "../../../helpers/Translate";
import Select from "react-select";
import GridContainer from "../../Grid/GridContainer";
import GridItem from "../../Grid/GridItem";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import moment from "moment";
import { useNavigate } from "react-router";
import { wrapper_get, wrapper_post } from "../../../helpers/call_wrapper_funcs";

interface IFormData {
  id: string;
  Students: Array<any>;
  type: string;
  notes: string;
  topic: string;
  description: string;
  dateslot: any;
  timeslot: any;
  datetime: any;
  teacher: Array<any>;
  status: string;
}


const MeetingModal: React.FC<any> = (props) => {
  let t = new Translate();
  const Navigate = useNavigate();
  var now = new Date();
  now.setDate(now.getDate() + 1);
  now.setHours(10);
  now.setMinutes(0);
  now.setMilliseconds(0);
  const [formData, setFormData] = useState<IFormData>({
    id: "",
    Students: [],
    type: "",
    notes: "",
    topic: "Parent-teacher conference",
    description: "",
    timeslot: "",
    dateslot: "",
    datetime: now,
    teacher: [],
    status: "Pending",
  });
  const [approve, setApprove] = useState(false);
  const [studentOptions, setStudentOptions] = useState<{ value: string; label: string }[]>([]);
  const [teacherOptions, setTeacherOptions] = useState<{ value: string; label: string }[]>([]);
  const [dateSlotOptions, setDateSlotOptions] = useState<{ value: string; label: string }[]>([]);
  const [timeslotOptions, setTimeSlotOptions] = useState<{ value: string; label: string }[]>([]);

  const [change, setChange] = useState(false);

  useEffect(() => {
    try {
      if (props.id !== null && props.id !== undefined && props.id !== "") {
        handleview(props.id);
      } else {
        Swal.showLoading();
        wrapper_get(`${process.env.REACT_APP_BASEURL}student/meeting/getmeetingutils.php`)
          .then((res: any) => {
            //get student options and setting them and location options
            const newstdOptions: { value: string; label: string }[] =
              res.data.data.students?.map((std: any) => {
                return { value: std.id, label: `${std.fullname} - ${std.id}` };
              });
            setStudentOptions(newstdOptions);
            setChange(true);

          }).finally(() => {
            Swal.close();
          })
      }
    } catch {
      Swal.fire("Error while grapping Meetings!", "", "error")
        .then(() => {
          setFormData((prevstate: any) => ({
            ...prevstate,
            id: "",
            Students: [],
            type: "",
            notes: "",
            topic: "Parent-teacher conference",
            description: "",
            timeslot: "",
            datetime: "",
            teacher: "",
          }));
        })
        .finally(() => {

        });
    }
  }, []);
  const handleview = (id: any) => {
    Swal.showLoading();
    wrapper_post(`${process.env.REACT_APP_BASEURL}student/meeting/getmeeting.php`, { id: id })
      .then((res: any) => {
        const newstdOptions: { value: string; label: string }[] =
          res.data.data?.students?.map((std: any) => {
            return { value: std.id, label: `${std.fullname} - ${std.id}` };
          });
        setStudentOptions(newstdOptions);
        const newteacherOptions: { value: string; label: string }[]
          = res.data.data?.teachers?.map((t: any) => {
            return { value: t.teacherid, label: `${t.teachername}  - ${t.subject === null ? "" : t.subject}` }
          })
        setTeacherOptions(newteacherOptions);
        const dateSlotsOptions: any = res.data.data?.dateslots?.map((slot: any) => {
          return { value: slot.id, label: `${slot.date}` };
        });
        setDateSlotOptions(dateSlotsOptions);
        const timeSlotsOptions: any = res.data.data?.timeslots?.map((slot: any) => {
          return { value: slot.id, label: `${slot.time}` };
        });
        setTimeSlotOptions(timeSlotsOptions);

        if (
          res.data.data.meeting.status === "Approved" ||
          res.data.data.meeting.status === "Declined"
        ) {
          setApprove(true);
          if (window.location.href.split("?")[1]) {
            Navigate(
              window.location.href.split("?")[0]
            );
          }
        } else {
          setApprove(false);
        }
        setFormData((prevstate: any) => (
          {
            ...prevstate,
            id: res.data.data.meeting.id,
            Students: newstdOptions?.filter(
              (f: any) => res.data.data.meeting?.students?.search(f.value) !== -1
            ),
            type: res.data.data.meeting.type,
            notes: res.data.data.meeting.notes,
            topic: res.data.data.meeting.subject,
            description: res.data.data.meeting.description,
            datetime: res.data.data.meeting.date,
            teacher: newteacherOptions?.filter(
              (f: any) => res.data.data.meeting?.teachers?.search(f.value) !== -1
            ),
            dateslot: dateSlotsOptions?.find(
              (f: any) => f.value === res.data.data.meeting.dateslot_id
            ),
            timeslot: timeSlotsOptions?.find(
              (f: any) => f.value === res.data.data.meeting.timeslot_id
            ),
            status: res.data.data.meeting.status,

          }));
      }).finally(() => { Swal.close(); });

  };
  const submitHandler = (e: React.FormEvent) => {
    e.preventDefault();
    Swal.showLoading();
    //---------- Validation -------------
    if (formData.Students.length === 0) {
      Swal.fire("Warning", "Please Select Students!", "warning");
      return;
    }
    if (formData.teacher.length === 0) {
      Swal.fire("Warning", "Please Select at least one teacher !", "warning");
      return;
    }
    if (formData.topic === "") {
      Swal.fire("Warning", "Please Enter a valid Topic !", "warning");
      return;
    }
    if (dateSlotOptions?.length > 0) {
      if (formData.dateslot === "") {
        Swal.fire("Warning", "Please select a valid Date !", "warning");
        return;
      }
      if (formData.timeslot === "") {
        Swal.fire("Warning", "Please select a valid Time Slot !", "warning");
        return;
      }

    } else {
      if (formData.datetime === now) {
        Swal.fire("Warning", "Please Enter a valid datetime !", "warning");
        return;
      }
    }
    if (formData.type === "") {
      Swal.fire("Warning", "Please Select meeting Type!", "warning");
      return;
    }
    //---------------------------------
    wrapper_post(`${process.env.REACT_APP_BASEURL}student/meeting/addmeeting.php`, formData)
      .then((res: any) => {
        Swal.fire("Added Successfully", "", "success")
          .then(() => {
            let err = "";
            if (typeof (res.data.msg) === "object") {
              res.data.msg.forEach((e: string) => {
                err += "<br>" + e;
              });
              if (err !== "") {
                Swal.fire("Kindly Note That", err, "info");
              } else {
                if (window.location.href.split("?")[1]) {
                  Navigate(
                    window.location.href.split("?")[0]
                  );
                } else {
                  props.closeModal();
                }
              }
            }
          })
      })
      .catch((e) => {
        let ee = e as any;
        Swal.fire(
          ee?.response?.data.msg || "Failed to login",
          "",
          "error"
        ).then(() => { });
      });
  };
  const handleStudentSelect = (selectedOption: any) => {
    setFormData((prevstate: any) => ({
      ...prevstate,
      Students: selectedOption,
    }));
    Swal.showLoading();
    wrapper_post(
        `${process.env.REACT_APP_BASEURL}student/meeting/getstudentteachers.php`,
        {
          selected:
            selectedOption?.map((student: any) => student.value).join(",") ??
            "",
        }
      )
      .then((res: any) => {
        const newteacherOptions: { value: string; label: string }[] =
          res.data.data?.map((std: any) => {
            return {
              value: std.teacherid,
              label: `${std.teachername} ${std.subject === null ? "" : `- ${std.subject}`
                }`,
            };
          });
        setTeacherOptions(newteacherOptions);
      }).finally(() => {
        Swal.close();
      });
  };
  const handleTeacherSelect = (selectedOption: any) => {
    setFormData((prevstate: any) => ({
      ...prevstate,
      teacher: selectedOption,
    }));
    Swal.showLoading();
    wrapper_post(`${process.env.REACT_APP_BASEURL}student/meeting/getteachertimeslots.php`, { selected: selectedOption ?? "" })
      .then((res: any) => {
        const dateOptions: { value: string; label: string }[] =
          res.data.data?.map((slot: any) => {
            return { value: slot.id, label: slot.date };
          });
        setDateSlotOptions(dateOptions);
      }).finally(() => {
        Swal.close();
      });
  };
  const handleTimeSlotSelect = (selectedOption: any) => {
    setFormData((prevstate: any) => ({
      ...prevstate,
      timeslot: selectedOption,
    }));
  };

  const handleDateSlotSelect = (selectedOption: any) => {
    setFormData((prevstate: any) => ({
      ...prevstate,
      dateslot: selectedOption,
    }));
    Swal.showLoading();
    wrapper_post(
        `${process.env.REACT_APP_BASEURL}student/meeting/getselecteddate.php`,
        { selected: selectedOption ?? "", teacher: formData.teacher }
      )
      .then((res: any) => {
        const timeSlotsOptions: { value: string; label: string }[] =
          res.data.data?.map((slot: any) => {
            return { value: slot.id, label: `${slot.time}` };
          });
        setTimeSlotOptions(timeSlotsOptions);
      }).finally(() => {
        Swal.close();
      });
  };

  if (
    window.location.href.split("?")[1] &&
    change
  ) {
    handleview(window.location.href.split("?")[1]);
    setChange(false);
  }
  const handleChange = (e: any) => {
    var name: any = "datetime";
    var value: any = "";

    if (e._isAMomentObject || e.target === null || e.target === undefined) {
      if (e._isAMomentObject) {
        value = e.format();
      } else {
        value = new Date().toISOString();
      }
    } else {
      name = e.target.name;
      value = e.target.value;
    }
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };
  const today = moment().subtract(0, "day");
  const disablePastDt = (current: any) => {
    return current.isAfter(today);
  };
  console.log(formData)
  return (
    <Modal isOpen={true}>
      <ModalHeader>{t.translate("Add Meeting")}</ModalHeader>
      <ModalBody>
        <Form onSubmit={submitHandler}>
          <GridContainer>
            <GridItem md={6} sm={6} xs={12}>
              <label>{t.translate("Students")}</label>
              <Select
                isDisabled={props.id !== null}
                menuPortalTarget={document.body}
                value={formData.Students}
                placeholder="Choose student"
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                }}
                isClearable
                options={studentOptions}
                isSearchable={true}
                isMulti
                onChange={handleStudentSelect}
              />
            </GridItem>
            <GridItem md={6} sm={6} xs={12}>
              <label>{t.translate("Select Teacher")}</label>
              <Select
                isDisabled={props.id !== null}
                menuPortalTarget={document.body}
                value={formData.teacher}
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                }}
                isClearable
                isMulti
                options={teacherOptions}
                isSearchable
                onChange={handleTeacherSelect}
                required
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem md={6} sm={12} xs={12}>
              <label>{t.translate("Topic")}</label>
              <Input
                disabled={props.id !== null}
                style={{ width: "100%" }}
                name="topic"
                required
                placeholder="Write Down Topic of Discussion"
                onChange={handleChange}
                value={formData.topic}
              />
            </GridItem>
          </GridContainer>
          {dateSlotOptions?.length > 0 ? (
            <GridContainer>
              <GridItem md={6} sm={12} xs={12}>
                <label>{t.translate("Date")}</label>
                <Select
                  isDisabled={props.id !== null}
                  value={formData.dateslot}
                  onChange={handleDateSlotSelect}
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  }}
                  isClearable
                  isSearchable
                  options={dateSlotOptions}
                />
              </GridItem>
              <GridItem md={6} sm={12} xs={12}>
                <label>{t.translate("Time")}</label>
                <Select
                  isDisabled={props.id !== null}
                  menuPortalTarget={document.body}
                  value={formData.timeslot}
                  options={timeslotOptions}
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  }}
                  isClearable
                  isSearchable
                  onChange={handleTimeSlotSelect}
                  required
                />
              </GridItem>
            </GridContainer>
          ) :
            (
              <GridContainer >
                <GridItem md={6} sm={12} xs={12}>
                  <label>{t.translate("Request Desired Date")}</label>
                  <Datetime
                    value={new Date(formData.datetime)}
                    timeConstraints={{
                      hours: { min: 8, max: 16, step: 1 },
                      minutes: { min: 0, max: 59, step: 1 },
                      seconds: { min: 0, max: 59, step: 1 },
                    }}
                    initialValue={props.id !== null ? formData.datetime : now}
                    isValidDate={disablePastDt}
                    utc
                    dateFormat="MMMM Do YYYY"
                    onChange={(e) => handleChange(e)}
                  />
                  <input
                    className="form-control"
                    disabled={props.id !== null}
                    type="hidden"
                    placeholder="Date..."
                    value={formData.datetime}
                    name="date"
                    onChange={(e) => handleChange(e)}
                    required
                  />
                </GridItem>
              </GridContainer>
            )
          }

          <GridContainer>
            <GridItem md={6} sm={12} xs={12}>
              <label>{t.translate("Type")}</label>
              <Input
                style={{ height: "40px", marginBottom: "10px" }}
                disabled={props.id !== null}
                name="type"
                type="select"
                required
                placeholder="Select Meeting Type"
                onChange={handleChange}
                value={formData.type}
              >
                <option value="0">{"Select Meeting Type"}</option>
                <option value={"online"}>{"online"}</option>
                <option value={"At School"}>
                  {"On School Premises"}
                </option>
              </Input>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem md={12} sm={12} xs={12}>
              <label>{t.translate("Description")}</label>
              <textarea
                style={{ width: "100%" }}
                disabled={props.id !== null}
                name="description"
                onChange={handleChange}
                value={formData.description}
              ></textarea>
            </GridItem>
          </GridContainer>
          {window.location.href.split("?")[1] ? (
            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
              <input
                type="submit"
                disabled={
                  formData.Students?.length == 0 ||
                  formData.teacher?.length == 0
                }
                value={t.translate("Submit")}
                className="btn btn-primary w-100 m-3"
              />
              <input
                type="submit"
                value={t.translate("Back")}
                onClick={(e: any) => {
                  e.preventDefault();
                  Navigate(window.location.href.split("?")[0])
                }}
                className="btn btn-danger w-100 m-3"
              />
            </div>
          ) : (
            <input
              type="submit"
              disabled={
                formData.status != "Pending" ||
                formData.Students === null ||
                formData.teacher === null ||

                formData.teacher === null ||
                formData.datetime === null ||
                formData.dateslot === null ||
                formData.timeslot === null
              }
              value={t.translate("Submit")}
              className="btn btn-primary w-100 mt-3"
            />
          )
          }
        </Form>
      </ModalBody>
      <ModalFooter>
        <button
          className="btn btn-secondary"
          onClick={() => {
            props.closeModal();
            setFormData((prevstate: any) => ({
              ...prevstate,
              id: "",
              Students: [],
              type: "",
              notes: "",
              topic: "",
              description: "",
              timeslot: "",
              datetime: now,
              teacher: "",
              dateslot: "",
            }));
          }}
        >
          {t.translate("Cancel")}
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default MeetingModal;
