import React, { useEffect, useState } from "react";
import { Col, Form, Input, Modal, ModalBody, ModalHeader, Row } from "reactstrap";

import Swal from "sweetalert2";
import { wrapper_get } from "../../helpers/call_wrapper_funcs";


interface FormData {
  installment_id: string;
  installmentAmount: number;
}


const Installments = () => {
  const [installments, setInstallments] = useState([]);
  const [modal, setModal] = useState(false);
  const [formData, setFormData] = useState<FormData>();
  const getInstallments = () => {
    try {
      wrapper_get(`${process.env.REACT_APP_BASEURL}guardian/installments/getinstallments.php`).then(
        (res: any) => {
          console.log(res.data.data);
          setInstallments(res.data.data)

        }
      )
    } catch {
      Swal.fire('Error while grapping interventions!', '', 'error');
    }
  }


  useEffect(() => {
    getInstallments();
  }, [])
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value
    setFormData((prevState: any) => ({ ...prevState, installmentAmount: value }));
  }

  const submitFileHandler = (e: any) => {
    e.preventDefault();
    console.log(formData)
  }

  return (
    <div className="row">

      {
        installments?.map((installment: any, index: number) => {
          return (
            <div className="col-md-4" key={index}>
              <div className="card shadow-sm p-3 mb-5 bg-white" style={{ borderRadius: "16px" }}>
                <br />


                <div className="card-body">
                  <p className="card-title">Student name : {installment.fullname}</p>
                  <p className="card-title">Student Id : {installment.student_id}</p>
                  <p className="card-title">Due date : {installment.date}</p>
                  <p className="card-title">Amount : {installment.amount}</p>
                </div>

                <div className="card-body ">
                  {/* <a href="#" className="card-link">Card link</a>
                  <a href="#" className="card-link">Another link</a> */}
                  <button className="btn btn-primary" style={{ maxWidth: "150px" }} onClick={() => {
                    setModal(true);
                    // setFormData({installment_id : installment.id , installmentAmount :installment.amount });
                  }}>
                    Pay
                  </button>

                </div>

              </div>

            </div>
          )
        }
        )

      }




      <Modal isOpen={true} className="sizeModal">
        <ModalHeader>Pay installment:</ModalHeader>
        <ModalBody>
          <Form onSubmit={submitFileHandler}>
            <Row className="justify-content-center" >


              <Col md={12}>
                <div className="form-group">
                  <label htmlFor="Amount">Amount</label>
                  <Input type="number" name="Amount" required placeholder="Amount" value={formData?.installmentAmount} onChange={(e) => handleChange(e)} />
                </div>
              </Col>

              <Col md={3}>
                <Input type="submit" className="btn btn-primary mt-3" style={{ backgroundColor: "blue" }} value="Submit" />
                <Input className="btn btn-danger mt-3" style={{ backgroundColor: "red" }} onClick={() => setModal(false)} value="Cancel" />
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
    </div>
  );
}


export default Installments;