import React, { Dispatch, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { actionCreators as StudnetCreator } from "../../../store/student";
import { Card, CardBody, CardHeader } from 'reactstrap';
import Swal from 'sweetalert2';
  
import { PrintGrades } from '../../../utils/helperFunc';
import Select from 'react-select';
import { Translate } from '../../../helpers/Translate';
import LineChart from './LineChart';
import SubjectMapGrowth from './SubjectMapGrowth';
import { wrapper_post } from '../../../helpers/call_wrapper_funcs';

export default function MapGrowth() {

    const { id } = useParams<{ id?: string }>();
    const students = useSelector((state: any) => state.Student.students);
    const student = students.find((st: any) => st.id == id);
    const [mapGrowthData, setMapGrowthData] = useState([]);

    const t = new Translate()


    useEffect(() => {
        try {
            wrapper_post(`${process.env.REACT_APP_BASEURL}student/mapgrowth/getmapgrowth.php`, { student_id: id }).then(
                (res: any) => {
                    setMapGrowthData(res.data.data);
                }
            )
        } catch {
            Swal.fire(t.translate('Error while getting courses!'), '', 'error');
        }
    }, []);








    //    console.log(typeof mapGrowthData)

    return (
        <div className="row">
            <div className="text-danger">
                {(student) ? <h3 className="mb-5">{student.fullname} : <small style={{ fontStyle: 'italic' }}>{student.id} : {student.grade}</small></h3> : <></>}
            </div>
            <Card className="w-100">
                <CardHeader>
                    <h4 className="title">{t.translate("Map GROWTH")}</h4>
                </CardHeader>
                <CardBody className="all-icons">


                    <div className="row mb-5">
                        <div className="col-6"> <strong>What is this report? </strong> <br /> A summary of how your child is performing academically, as measured by the most recent MAP Growth test. </div>
                        <div className="col-6"> <strong>What is MAP Growth?</strong> <br /> A test that adapts to your child's responses in real time to measure your child's skill level. </div>
                    </div>
                    <div className="row mb-5">
                        <div className="col-6"><strong>Why is my child taking MAP Growth? </strong> <br /> MAP Growth scores help teachers check student performance by measuring Achievement and Growth. Teachers use results to tailor classroom lessons and to set goals for students. </div>
                        <div className='col-6'><strong>What do Achievement and Growth mean? </strong> <br />
                            <div className='mt-2 mb-2'> <strong >Achievement-</strong>How well your child has learned skills in a subject compared to similar students nationwide.* </div>
                            <strong>Growth-</strong>A measure of your child's personal progress over the year.

                        </div>
                    </div>
                    <div  className=' pl-0 my-5 col-6'><strong>What is a RIT score? </strong> <br /> The overall score for a subject based on a Rasch unit (RIT) scale that indicates how your child performed in a subject.
                        <br />  <br /> *Similar students — kids with same starting RIT score, same number of weeks of instruction, and in the same grade </div>




                    {


                        mapGrowthData.map((subjectData: any) => {

                            return (
                                <SubjectMapGrowth subjectData={subjectData}  student={student}/>
                            )
                        })



                    }
                 




                </CardBody>
            </Card>
        </div>


    );
}