import React, { useEffect, useState, useRef } from "react";
import { Card, CardBody, CardHeader, CardTitle, CardText, Button, CardFooter } from "reactstrap";
import Swal from "sweetalert2";
import "react-datetime/css/react-datetime.css";
import moment from "moment";

import {
    ModalBody,
    Modal,
    ModalHeader,
    ModalFooter,
    Form,
    Input,
    FormGroup,
} from "reactstrap";
import { Translate } from "../../../helpers/Translate";
import $ from 'jquery'
import Select from "react-select";
import "./walloflove.css";
import { useSelector } from "react-redux";
import { wrapper_post } from "../../../helpers/call_wrapper_funcs";

interface IFormData {
    id: string;
    parent?: string;
    students?: Array<string>;
    title: string;
    body: string;
    student_id?: string;
    drive_id: string;
    date?: string;
    status?: number | string;
    status_id?: number | string;
    video_image_id?: string;
}

const mapStatusToColor: any = {
    1: '#b8860b',
    2: '#008000',
    3: '#008000',
    4: '#000'
}

// $media = '<video controls id="'.$value->id.'" preload="none" poster="https://drive.google.com/uc?id='.$value->video_image_id.'&export=download" style="width:100%; max-height:50%;">
//                   <source src="https://drive.google.com/uc?id='.$value->drive_id.'&export=download" type="video/mp4">
//                   Your browser does not support the video tag.
//               </video>';

const EarlyDismissal: React.FC = () => {
    let t = new Translate();
    const guardian = useSelector((state: any) => state.Parent.guardian);

    var now = new Date();
    now.setDate(now.getDate() + 1);
    now.setHours(10);
    now.setMinutes(0);
    now.setMilliseconds(0);
    const [modal, setModal] = useState(false);
    const [edit, setEdit] = useState(false);
    const [add, setAdd] = useState(false);
    const [isViewBlog, setIsViewBlog] = useState(false);
    const [img, setImg] = useState(" ");
    const [blogs, setBlogs] = useState<any>([]);
    const [previewImg, setPrveiewImg] = useState<any>("");


    const [file, setFile] = useState<any>(null);
    const [formData, setFormData] = useState<IFormData>({
        id: "0",
        parent: guardian.id,
        students: [],
        title: "",
        body: "",
        drive_id: "",
        status_id: 4,
        video_image_id: ""

    });
    const [selectedFile, setSelectedFile] = useState<any>(null);


    // console.log(guardian);
    useEffect(() => {
        try {
            wrapper_post(
                `${process.env.REACT_APP_BASEURL}student/blogs/getblogs.php`,
                { parent: guardian.id }
            )
                .then((res: any) => {
                    setBlogs(res.data.data)
                });
        } catch {
            Swal.fire(t.translate("Error while grapping blogs!"), "", "error")

        }
    }, [add]);
    function getThumbnail(file: any) {

        let fileReader: any = new FileReader();
        if (file.type.match('image')) {
            fileReader.onload = function () {
                setPrveiewImg(fileReader.result)
                // $('#blah').attr('src', fileReader.result);
                // console.log(fileReader.result);
            };
            fileReader.readAsDataURL(file);
        } else {
            fileReader.onload = function () {
                let blob = new Blob([fileReader?.result], {
                    type: file.type
                });
                let video = document.createElement('video');

                const snapImage = () => {
                    let canvas: any = document.createElement('canvas');
                    canvas.width = video.videoWidth;
                    canvas.height = video.videoHeight;
                    canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);
                    let image = canvas.toDataURL();
                    let success = image.length > 100000;
                    // $('#blah').attr('src', image);
                    setPrveiewImg(image)


                    // Thumbnail --- image  base64
                    if (success) {
                        $("#video_image").val(image);
                    }
                    return success;
                };
                let url = URL.createObjectURL(blob);
                let timeupdate = () => {
                    if (snapImage()) {
                        video.removeEventListener('timeupdate', timeupdate);
                        video.pause();
                    }
                };
                video.addEventListener('loadeddata', function () {
                    if (snapImage()) {
                        video.removeEventListener('timeupdate', timeupdate);
                    }
                });

                video.addEventListener('timeupdate', timeupdate);
                video.preload = 'metadata';
                video.src = url;
                // Load video in Safari / IE11
                video.muted = true;
                video.play();
            };
            fileReader.readAsArrayBuffer(file);
        }


    }
    const handleSubmit = (e: any) => {
        e.preventDefault();
        // console.log(selectedFile);
        if (!edit && !selectedFile) {
            Swal.fire(t.translate("Please upload the blog media"), "", "error");
            return
        }
        Swal.showLoading();
        const blogData = new FormData();

        blogData.append('title', formData.title)
        blogData.append('body', formData.body)
        blogData.append('blogmedia', selectedFile)
        blogData.append('id', formData.id)
        blogData.append('parent', guardian.id)
        if (selectedFile && selectedFile.type.match('video')) {
            blogData.append('videoimg', previewImg)
        }

        const res = wrapper_post(
                `${process.env.REACT_APP_BASEURL}/student/blogs/addblog.php`,
                blogData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            )
            .then((res) => {
                clearState();
                Swal.fire("Saved Successfully", "", "success").then(() => {
                    setModal(false);
                    setAdd(!add);
                    setSelectedFile(null)
                    // setBlogs([...blogs, res.data.data])
                });
            })
            .catch((e) => {
                let ee = e;
                Swal.fire(ee?.response?.data.msg || "Failed to upload", "", "error");
            });
    };

    const handleAddModal = async () => {
        clearState();
        setModal(true);
        setEdit(false);
    };

    const clearState = () => {
        setFormData({
            id: "0",
            parent: guardian.id,
            students: [],
            student_id: "",
            title: "",
            body: "",
            drive_id: ""
        });
        setPrveiewImg("")
    };

    const handleStudentstSelect = (selectedOption: any) => {
        if (selectedOption) {
            setFormData((prevState) => ({
                ...prevState,
                students: selectedOption,
                student_id: selectedOption.value,
            }));
        } else {
            setFormData((prevState) => ({ ...prevState, student_id: "" }));
        }
    };
    const today = moment().subtract(1, "day");
    const disablePastDt = (current: any) => {
        return current.isAfter(today);
    };

    const handleEditBlog = (e: any, blog: any) => {
        // Swal.showLoading();

        clearState();

        let editIndex = blogs.findIndex((item: any) => item.id === blog.id)
        if (editIndex !== -1) {
            let foundBlog = blogs[editIndex]

            setFormData({
                id: foundBlog.id,
                parent: guardian.id,
                students: [],
                student_id: "",
                title: foundBlog.title,
                body: foundBlog.body,
                drive_id: foundBlog.drive_id,

            });
            setEdit(true);

            setModal(true);

        } else {
            clearState();
            setEdit(false);
            setModal(false);
            Swal.fire(t.translate("Error while editing blog"), '', 'error')
        }

    };
    const handleViewBlog = (e: any, blog: any) => {
        // Swal.showLoading();


        let editIndex = blogs.findIndex((item: any) => item.id === blog.id)
        console.log(editIndex);
        if (editIndex !== -1) {
            let foundBlog = blogs[editIndex]
            // console.log(foundBlog);

            setFormData({
                id: foundBlog.id,
                parent: guardian.id,
                students: [],
                student_id: "",
                title: foundBlog.title,
                body: foundBlog.body,
                drive_id: foundBlog.drive_id,
                status: foundBlog.status,
                status_id: foundBlog.status_id,
                date: foundBlog.date,
                video_image_id: foundBlog.video_image_id
            });
            setIsViewBlog(true);

            // setModal(true);

        } else {
            clearState();
            // setView(false);
            // setModal(false);
            setIsViewBlog(false);
            Swal.fire(t.translate("Error while editing blog"), '', 'error')
        }

    };

    const [selectedFileTest, setSelectedFileTest] = useState<any>("")

    const handleChange = (e: any) => {
        var name: any = "";
        var value: any = "";

        if (e._isAMomentObject || e.target === null || e.target === undefined) {
            if (e._isAMomentObject) {
                value = e.format();
            } else {
                value = new Date().toISOString();
            }
        } else {
            name = e.target.name;
            value = e.target.value;
        }
        setFormData((prevState) => ({ ...prevState, [name]: value }));
    };


    const testSubmit = (e: any) => {
        e.preventDefault()
        console.log('herger');
        getThumbnail(selectedFileTest)
    }
    return (
        <>


            <img src="" id="blah" alt="" />
            <img src="" id="video_image" alt="" />
            <div className="row justify-content-around w-100 mx-0 my-5 linkssec gap-3">
                <div className="col-12">
                    <button className="btn btn-primary my-3" style={{ width: '200px', marginRight: 'auto' }} onClick={handleAddModal}>
                        {t.translate("Add Post")}
                    </button>
                </div>
                {blogs.length > 0
                    ? blogs.map((blog: any) => {

                        return (

                            <Card
                                className="col-xs-12 col-sm-12 col-md-5 p-1"
                                style={{
                                    // width: '18rem'
                                }}
                                key={blog?.id + blog?.title}
                            >
                                <CardBody className="px-0">
                                    <CardHeader>
                                        <CardTitle tag="h5">
                                            {blog?.title}
                                        </CardTitle>
                                    </CardHeader>

                                </CardBody>

                                {blog?.video_image_id != ""

                                    ? <img
                                        alt="Card cap"
                                        src={blog.video_image_id?.includes("https://") ? blog.video_image_id : `http://drive.google.com/uc?export=view&id=${blog?.video_image_id}`}
                                        width="100%"
                                        style={{ height: '200px', objectFit: 'cover' }}
                                    />

                                    :
                                    <img
                                        alt="Card cap"
                                        src={blog.drive_id?.includes("https://") ? blog.drive_id : `http://drive.google.com/uc?export=view&id=${blog?.drive_id}`}
                                        width="100%"
                                        style={{ height: '200px', objectFit: 'cover' }}
                                    />
                                }

                                <CardBody>
                                    <CardText>
                                        {blog?.body}

                                    </CardText>

                                </CardBody>

                                <CardFooter>
                                    <Button className="btn-primary"
                                        onClick={(e: any) => handleEditBlog(e, blog)}
                                        style={{ marginRight: '3%' }}
                                        disabled={blog?.status_id == 2}
                                    >
                                        {t.translate("Edit")}
                                    </Button>
                                    <Button className="btn-warning"
                                        style={{ marginRight: '3%' }}
                                        onClick={(e: any) => { handleViewBlog(e, blog) }}
                                    >
                                        {t.translate("View")}
                                    </Button>


                                    <p className="text-info fs-5 mt-1">{t.translate("created at")} : {blog?.date}</p>
                                    <p className="text-info fs-5" >{t.translate("Status")} :
                                        <span style={{ color: mapStatusToColor[blog?.status_id] }}>
                                            {blog?.status == "Approved" || blog?.status == "Rejected" ? "Confirmed" : "Pending"}</span></p>
                                </CardFooter>
                            </Card>
                        )
                    })
                    : <h4>{t.translate("You Have No Posts")}</h4>
                }


            </div>

            <Modal className="sizeModal" isOpen={isViewBlog}>

                <ModalHeader> {t.translate("View")}</ModalHeader>
                <ModalBody>
                    <h1 className="text-center">{formData.title}</h1>
                    <p className="text-info mt-1">{t.translate("created at")} : {formData?.date}</p>
                    <p className="text-info " >{t.translate("Status")} : <span style={{}}>{formData?.status == "Approved" || formData?.status == "Rejected" ? "Confirmed" : "Pending"}</span></p>

                    <p className="text-center">{formData.body}</p>
                    {formData.video_image_id
                        ? <div className="mt-3" style={{ textAlign: 'center' }}>
                            <video controls id="'.$value->id.'" preload="none"
                                poster={formData.video_image_id?.includes("https://") ? formData.video_image_id : `https://drive.google.com/uc?id=.$value->${formData.video_image_id}.'&export=download`} style={{ width: "100%", maxHeight: "50%" }}>
                                <source src={formData.drive_id?.includes("https://") ? formData.drive_id : `https://drive.google.com/uc?id=${formData.drive_id}&export=download" type="video/mp4`} />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                        : <div className="mt-3" style={{ textAlign: 'center' }}>
                            <img
                                alt="Card cap"
                                src={formData.drive_id?.includes("https://") ? formData.drive_id : `http://drive.google.com/uc?export=view&id=${formData.drive_id}`}
                                width="30%"
                                style={{ textAlign: 'center' }}
                            />

                        </div>}


                </ModalBody>

                <ModalFooter>
                    <button className="btn btn-secondary" onClick={() => setIsViewBlog(false)}>
                        {t.translate("Close")}
                    </button>
                </ModalFooter>
            </Modal>


            <Modal className="sizeModal" isOpen={modal}>
                <ModalHeader> {t.translate("Post")}</ModalHeader>
                <ModalBody>
                    <Form onSubmit={handleSubmit}>
                        {/* <label style={{ marginBottom: "2%", marginTop: "2%" }}>
                            {t.translate("Select Student")}
                        </label>

                        <Select
                            isClearable
                            value={
                                studentsOptions?.find(
                                    (f: any) => f.value === formData?.student_id
                                ) ?? ""
                            }
                            options={studentsOptions}
                            onChange={handleStudentstSelect}
                            menuPortalTarget={document.body}
                            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                        /> */}

                        <label style={{ marginBottom: "2%", marginTop: "2%" }}>
                            {t.translate("Title")}
                        </label>
                        <Input
                            className="form-control"
                            name="title"
                            value={formData?.title}
                            onChange={(e: any) => {
                                handleChange(e);
                            }}
                        ></Input>


                        <div style={{ width: "100%" }} className="mt-3">
                            <label> {t.translate("Content")}</label>
                            <textarea
                                // type="text"
                                className="form-control"

                                rows={8}
                                name="body"
                                value={formData.body}
                                onChange={(e: any) => handleChange(e)}
                            />
                        </div>

                        {formData.drive_id && <div className="mt-3">
                            <img
                                alt="Card cap"
                                src={formData.drive_id?.includes("https://") ? formData.drive_id : `http://drive.google.com/uc?export=view&id=${formData.drive_id}`}
                                width="30%"

                            />
                            <p className="mb-1"><span className="text-danger">{t.translate("note:")}</span> {t.translate("this is the previous media you uploaded")}</p> <p>{t.translate("if you want to change upload new one below, if you want to keep leave file input empty")}</p>
                        </div>}
                        <div style={{ width: "100%" }} className="mt-3">
                            <label> {t.translate("Media")}</label>
                            <input
                                type="file"
                                className="form-control"
                                name="blogmedia"
                                onChange={(e: any) => {
                                    // console.log(e.target.files[0]);
                                    setSelectedFile(e.target.files[0])
                                    getThumbnail(e.target.files[0])
                                }}
                            />
                        </div>

                        {previewImg &&
                            <div style={{ width: "100%" }} className="mt-3">

                                <img
                                    alt="Card cap"
                                    src={previewImg}
                                    width="30%"
                                />

                            </div>
                        }


                        <Input
                            type="submit"
                            disabled={
                                // !selectedFile ||
                                formData.title === "" ||
                                formData.body === ""
                            }
                            className="btn btn-primary mt-3"
                            style={{ backgroundColor: "blue" }}
                            value={t.translate("Submit")}
                        />
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <button className="btn btn-secondary" onClick={() => setModal(false)}>
                        {t.translate("Cancel")}
                    </button>
                </ModalFooter>
            </Modal>
        </>
    );
};

export default EarlyDismissal;
