import React, { useEffect, useState } from "react";
import MDBDataTableV5 from "../../../components/CustomDataTable";
import { Spinner, Card, CardBody, CardHeader } from "reactstrap";
import Swal from "sweetalert2";
 
import { Link } from "react-router-dom";
import { Translate } from "../../../helpers/Translate";
import InterventionModal from "./InterventionModal";
import { wrapper_get } from "../../../helpers/call_wrapper_funcs";

const AcademicInterventions: React.FC = () => {
  let t = new Translate();
  const [modal, setModal] = useState(false);
  const [p, setP] = useState(false);
  const [interventionId, setInterventionId] = useState(0);
  const [intDataTable, setIntDataTable] = useState({
    columns: [
      {
        label: "#",
        field: "id",
      },
      {
        label: t.translate("Student ID"),
        field: "student_id",
      },
      {
        label: t.translate("Student Name"),
        field: "student_name",
      },
      {
        label: t.translate("Teacher"),
        field: "teacher_name",
      },
      {
        label: t.translate("Course Name"),
        field: "coursename",
      },
      {
        label: t.translate("Lesson"),
        field: "lesson",
      },
      {
        label: t.translate("Status"),
        field: "status",
      },

      {
        label: t.translate("View"),
        field: "view",
      },
    ],
    rows: [
      {
        id: <Spinner color="info" />,
        student_id: <Spinner color="info" />,
        student_name: <Spinner color="info" />,
        teacher_name: <Spinner color="info" />,
        coursename: <Spinner color="info" />,
        lesson: <Spinner color="info" />,
        status: <Spinner color="info" />,

        view: <></>,
      },
    ],
  });


  const handleViewModal = (id: any) => {
    console.log(id)
    setInterventionId(id)
  }

  useEffect(() => {
    try {
      wrapper_get(`${process.env.REACT_APP_BASEURL}student/academicinterventions/getinterventions.php`)
        .then((res: any) => {
          console.log(res.data.data);
          setIntDataTable((prevState) => {
            const newState = { ...prevState };
            let ser = 1;
            // change .exams below to .homeworks after backend finished
            let newRows = res.data.data.map((i: any, key: any) => {
              return {
                ...i,
                id: ser++,
                student_id: i.student_id,
                student_name: i.student_name,

                teacher_name: i.teacher_name,
                view: (
                  <button
                    onClick={() => { handleViewModal(i.id) }}
                    className="btn btn-info"
                  >
                    {t.translate("View")}
                  </button>
                ),
              };
            });
            return { ...newState, rows: newRows };
          });
        }).catch((error) => {
          Swal.fire("Error while grapping AcademicInterventionss!", "", "error");
        })
    } catch {
      Swal.fire("Error while grapping AcademicInterventionss!", "", "error");
    }
  }, [p]);

  return (
    <>

      <InterventionModal modal={modal} interventionId={interventionId} setModal={setModal} setP={setP} p={p} />
      <div className="row justify-content-center w-100 mx-0 my-5 linkssec">
        <Card>
          <CardHeader>{t.translate("Academic Interventions")}</CardHeader>
          <hr />
          <button className="btn btn-primary" style={{ maxWidth: "300px" }} onClick={() => {
            setModal(true);
          }}>
            {t.translate("Request academic intervention")}
          </button>
          <CardBody className="bg-light">
            <MDBDataTableV5
              hover
              responsive
              borderless={false}
              bordered
              striped
              entriesOptions={[5, 20, 25]}
              entries={5}
              pagesAmount={4}
              data={intDataTable}
              fullPagination
              searchTop
              searchBottom={false}
            />
          </CardBody>
        </Card>
      </div>
    </>
  );
};

export default AcademicInterventions;
