//import { resolve } from "dns";
//import { rejects } from "assert";
//import * as Fingerprint2 from 'fingerprintjs2';
import { Convert } from './typeConv';
//import { isNullOrUndefined } from 'util';
import * as Initial from '../store/initialStatus';

import { url as Durl, ConnString } from '../store/initialStatus';

import Cookies from 'js-cookie';
import axios from 'axios';
import { wrapper_post } from './call_wrapper_funcs';

axios.defaults.withCredentials = true;
//import { resolve } from 'dns';


export function parseJwt(token: any) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
}


export async function requestWithAxios(url: any, data: any) {
  axios.defaults.headers.common['jwt'] = Cookies.get("guardiantoken");
  var result = await wrapper_post(
    url,
    data,

  )
    .then((res: any) => { return res })
    .catch((err: any) => { return err; });
  return result;
}

export var Request = (url = '', method = 'POST', data = {}, token = "") => {
  if (url.search('http:') === -1 && url.search('https:') === -1) { url = Durl + url; }
  let FingerPrint = sessionStorage.getItem('fp');
  if (FingerPrint === null) {
    FingerPrint = RandomString(32);
    sessionStorage.setItem('fp', FingerPrint);

  }
  return new Promise<{ status: number, json: any }>(async (resolve, rejects) => {
    let body: string;
    if (url === Initial.url + "/api/account/login") {
      let MSG = {
        Body: JSON.stringify(data)
      };
      body = (JSON.stringify(MSG));
    } else {
      let MSG = {
        Body: JSON.stringify(data)
      }
      body = (JSON.stringify(MSG));

    }
    let MyBody = { data: body }
    try {
      await fetch(url, {
        method: method, // *GET, POST, PUT, DELETE, etc.
        mode: 'no-cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',

          'time': (new Date()).toDateString(),
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        //form: JSON.stringify({id:"DSdsdsdsd"}),

        body: JSON.stringify(MyBody)  // body data type must match "Content-Type" header
      }).then(async (response) => {
        var json: any = {};
        /*          response.text().then(function (text) {
                    
                });  */
        try {
          //  
          json = await response.json();
        } catch (err) {

        }
        //  
        /* let decryptedObj;
        try {
          //  
          decryptedObj =  json.data;  
          delete decryptedObj['__type'];
          for(var i in decryptedObj){
            delete decryptedObj[i]['__type'];
          } 
        } catch (err) {
          decryptedObj = {}
       } */

        resolve({ status: response.status, json: json });
      }).catch((err) => {

        rejects(err);
      });
    } catch (error) {

      rejects(error)
    }
  })
  // Default options are marked with *

  //return response.json(); // parses JSON response into native JavaScript objects
}

export var ShortRequest = (url = '', method = 'post', data = {}) => {
  //  
  let conString = ConnString();
  let res = Request(url, method, data, conString.token);
  return res;
}

export function setCookie(name: string, val: string) {
  const date = new Date();
  const value = val;

  // Set it expire in 3 days
  date.setTime(date.getTime() + (3 * 24 * 60 * 60 * 1000));

  // Set it
  document.cookie = name + "=" + value + "; expires=" + date.toUTCString() + "; path=/";
}
export function getCookie(name: string) {
  const value = "; " + document.cookie;
  const parts = value.split("; " + name + "=");

  if (parts ? true : false && parts.length === 2) {
    let x = parts.pop();

    return x ? x.split(";").shift() : "";
  }
  return "";
}

export function deleteCookie(name: string) {
  const date = new Date();

  // Set it expire in -1 days
  date.setTime(date.getTime() + (-1 * 24 * 60 * 60 * 1000));

  // Set it
  document.cookie = name + "=; expires=" + date.toUTCString() + "; path=/";
}

export function asciiToUint8Array(s: string) {
  return new TextEncoder().encode(s);
}

export function RandomString(length: number) {
  var result = window.crypto.getRandomValues(new Uint8Array(length))
  return Convert.ab2base64str(result);
}

export function makeId(length: number) {
  var result = '';
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
export function objectsAreSame(x: any, y: any) {
  var objectsAreSame = true;
  for (var propertyName in x) {
    if (x[propertyName] !== y[propertyName]) {
      objectsAreSame = false;
      break;
    }
  }
  return objectsAreSame;
}

export function Masker(Arr: Array<any>, Mask: number | null | undefined) {
  let res: Array<any> = [];
  if (Mask === null || Mask === undefined) {
    for (let i = 0; i < Arr.length; i++) {
      if (!Arr[i].IsHidden) { res.push(Arr[i]); }

    }
    return res;
  }
  var bitArr = Convert.int2Binary(Mask);
  for (let index = 0; index < bitArr.length; index++) {
    if (bitArr[index] && !Arr[index].IsHidden) { res.push(Arr[index]); }

  }
  return res;
}
export function GenMask(Arr: Array<any>, subArr: Array<any>) {
  let res: Array<number> = [];
  for (let index = 0; index < Arr.length; index++) {
    let flag = false;
    for (let j = 0; j < subArr.length; j++) {
      if (Arr[index].value === subArr[j].value) {
        flag = true;
        break;
      }
    }
    if (flag) { res.push(1); }
    else {
      res.push(0);
    }
  }
  var Mask = 0;
  for (let index = 0; index < res.length; index++) {
    if (res[index] === 1) {
      Mask += Math.pow(2, index);
    }
  }
  return Mask;
}

export function LoadFromStore(VarName: string) {
  const serializedState = localStorage.getItem(VarName);
  if (serializedState === null) return undefined;
  let state = JSON.parse(serializedState);
  return state;
}

export function ListOfObj2SelectOptions(array: Array<any>) {
  //  
  let result: Array<{ value: string, label: string, color: string, isFixed: boolean, isDisabled?: boolean }> = [];
  let i = 1;
  array.forEach(element => {
    //  
    if (typeof (element) === "object")
      result.push({ value: element[Object.keys(element)[0]], label: element[Object.keys(element)[1]], isFixed: true, color: 'black' });
    else {
      result.push({ value: element, label: element, isFixed: true, color: "black" });
      i++;
    }
  });
  //  
  return result;
}

export function isNullOrUndefined(obj: any) {
  if (obj === null || obj === undefined) {
    return true;
  }
  return false;
}


export function isHtmlElement(obj: any) {
  try {
    //Using W3 DOM2 (works for FF, Opera and Chrome)
    let _ = obj instanceof HTMLElement;
    if (_ === null) { return false; }
    return true;
  }
  catch (e) {
    //Browsers not supporting W3 DOM2 don't have HTMLElement and
    //an exception is thrown and we end up here. Testing some
    //properties that all elements have (works on IE7)
    return false;
  }
}


export function NotSpecialType(element: string) {
  //if (element === undefined) { return true;}
  if (element.toLowerCase() === "string" || element.toLowerCase() === "bool" || element.toLowerCase() === "date" ||
    element.toLowerCase() === "int" || element.toLowerCase() === "datetime"
    || element.toLowerCase() === "email" || element.toLowerCase() === 'password' || element.toLowerCase() === 'phone'
  ) {
    return true;
  } else {
    return false;
  }
}



export function DateFormat(date: string) {
  let options: any = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' };
  let d = new Date(date);
  return d.toLocaleString('en-us', options)
}

export function PrintElem(content: any, width = 800, height = 500) {
  var mywindow: any = window.open('', 'PRINT', `height=${height},width=${width}`);
  mywindow.document.write(content);
  mywindow.document.close(); // necessary for IE >= 10
  mywindow.focus(); // necessary for IE >= 10*/
  mywindow.onafterprint = () => {
    console.log('After print');
    //mywindow.close();
  };
  mywindow.print();

  return true;
}

export function PrintInvoice(account_no: string, transaction_id: string = "", logo_appearance_flag: boolean = true) {
  const URL = `${Durl}transaction/gettransactions.php`;
  wrapper_post(URL, { account_no: account_no, transaction_id: transaction_id }).then((res: any) => {
    console.log(res.data.data);
    const lastTrans: any = (res.data.data)[(res.data.data).length - 1];
    console.log(lastTrans);

    let whoPay: string = '';
    if (lastTrans.other) {
      whoPay = lastTrans.other;
    } else {
      whoPay = lastTrans.parentname;
    }
    let printDemoHTML = `<!DOCTYPE html>
            <html lang="en">
            <head>
            <meta charset="UTF-8">
            <meta http-equiv="X-UA-Compatible" content="IE=edge">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <link href="https://ivyis.org/css/bootstrap.min.css" rel="stylesheet" >
            <title>Invoice</title>
            <style>
                body {
                text-align: center;
                }
                .container-fluid {
                max-height: 480px;
                width: 790px;
                }
                .ivy {
                height: 100px;
                width: 130px;
                }
                .ivy-h {
                    height: 60px;
                    width: 200px;
                }
                .title {
                color: rgb(116, 86, 12);
                text-align: center;
                }
                .text-item {
                color: rgb(21, 21, 71);
                }
                .h-table {
                border-top: none;
                border-left: none;
                border-right: none;
                border-bottom: 3px solid rgb(116, 86, 12);
                height: 40px;
                }
                .t-head{
                background-color: rgb(116, 86, 12);
                color: antiquewhite;
                font-weight: 400;
                }
                .t-details {
                text-overflow: initial;
                overflow-wrap: normal;
                word-wrap: normal;
                justify-content: center;
                align-content: center;
                }
                .amount-description {
                text-align: center;
                text-overflow: clip;
                word-wrap: break-word;
                }
                .cont-right {
                border: none;
                }
                .cont-left {
                border: none;
                }
                .det2 {
                border-left: 4px solid rgb(116, 86, 12);
                }
                .ivy-h{
                    background-color: #fff;
                }
                .text-left{
                    text-align: left;
                }
                .amdesc {
                border-radius: 15px;
                border: 4px solid rgb(116, 86, 12);
                }
            </style>
            </head>
            <body>
            <div class="container-fluid justify-content-center align-items-center p-0">
                <div class="logos row justify-content-evenly align-items-center mt-1">
                ${logo_appearance_flag ? '<img class="ivy col-4" src="https://ivyis.org/images/ivy_logo.png" alt="ivy_logo">' : ''}
                <h2 class="col-4 text-center fw-bolder title">CASH RECEIPT</h2>
                ${logo_appearance_flag ? '<svg class="ivy-h" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 220 60" style="enable-background:new 0 0 220 60;" xml:space="preserve">' : ''}
                <style type="text/css">
                    .st0{fill:#40486e;}
                    .st1{fill:#E3001B;}
                </style>
                <g id="Group_2755" transform="translate(-114.247 -247.286)">
                    <path id="Path_8223" class="st0" d="M156.8,277.2c1.8-8.3,6.3-22.1,9.1-28h10.3c2.8,5.9,7.4,19.7,9.1,28v0.3h-8.8   c-0.3-1.6-0.8-3.5-1.2-5.6h-9c-0.5,2.1-0.9,4-1.2,5.6h-8.2L156.8,277.2z M167.8,265.7h6c-1.2-4.8-2.3-9.3-2.9-10.9   C170.3,256.4,169,260.8,167.8,265.7L167.8,265.7z"/>
                    <path id="Path_8224" class="st0" d="M188,249.2h12.4c6.9,0,11.2,2.8,11.2,9.7c0,3.8-1.2,6-4.3,8.1c2.4,1.9,5,6.5,6.1,10.1v0.3h-8.3   c-0.8-2.9-3.2-6.9-5.3-8.3h-3.5v8.3H188L188,249.2z M199.2,263.3c3.1,0,4.1-1,4.1-4.1c0-3-1.2-3.8-4.1-3.8h-3v8L199.2,263.3z"/>
                    <path id="Path_8225" class="st0" d="M215.5,249.2h7.7l6.9,10.9l7.2-10.9h7.7v28.3h-8.3v-15.8l-5.9,8.1h-1.8l-5.3-8.1v15.8h-8.2   V249.2z"/>
                    <path id="Path_8226" class="st0" d="M248.2,262.5c0-8.7,4.6-13.6,13.3-13.6c8.7,0,13.2,4.9,13.2,13.6v1.7c0,8.7-4.6,13.6-13.2,13.6   c-8.7,0-13.3-4.9-13.3-13.6V262.5z M261.5,271.3c4.3,0,5-2.8,5-7.1v-1.7c0-4.3-0.8-7.1-5-7.1s-5,2.8-5,7.1v1.7   C256.5,268.5,257.2,271.3,261.5,271.3z"/>
                    <path id="Path_8227" class="st0" d="M278,249.2h7l10,14.7v-14.7h8.3v28.3h-7.1l-10-14.7v14.7H278L278,249.2z"/>
                    <path id="Path_8228" class="st0" d="M314.7,267l-9.3-17.5v-0.3h8.7l5.2,11.3l5.2-11.3h7.5v0.3l-9,17.2v10.7h-8.3L314.7,267z"/>
                    <g id="Group_2753" transform="translate(127.107 286.065)">
                        <g id="Group_2751" transform="translate(0 0.188)">
                            <path id="Path_8229" class="st0" d="M1.7,1.6h5.7c3.7,0,5.3,2,5.3,5.7s-1.8,6.1-5.7,6.1H3.9v5.9H1.7L1.7,1.6z M7,11.4     c2.9,0,3.5-1.7,3.5-4.1c0-2.6-0.7-3.8-3.3-3.8H3.9v7.9L7,11.4z"/>
                            <path id="Path_8230" class="st0" d="M13.5,19.1c1.6-6.7,4.2-14.4,5.9-17.5h2.9c1.6,3.1,4.3,10.7,5.9,17.5v0.2h-2.2     c-0.4-1.4-0.7-2.9-1.1-4.3h-8.1c-0.4,1.5-0.8,2.9-1.1,4.3h-2.1V19.1z M17.2,13h7c-0.9-3.3-2-6.5-3.5-9.6     C19.3,6.5,18.1,9.7,17.2,13z"/>
                            <path id="Path_8231" class="st0" d="M30.9,1.6h5.7c3.7,0,5.3,1.6,5.3,5.3c0.2,2.2-1.1,4.3-3.2,5c1.9,2,3.4,4.4,4.3,7.1v0.2h-2.3     c-0.9-2.5-2.3-4.7-4.1-6.6h-3.5v6.6h-2.2V1.6z M36,10.7c2.8,0,3.7-1.6,3.7-3.8c0-2.6-0.9-3.4-3.3-3.4h-3.3v7.2L36,10.7z"/>
                            <path id="Path_8232" class="st0" d="M49.4,3.5h-5.5V1.6h13.2v1.9h-5.5v15.7h-2.2V3.5z"/>
                            <path id="Path_8233" class="st0" d="M59.9,1.6h1.5l9.6,13.1V1.6h2.2v17.6h-1.5L62.1,6.2v13.1h-2.2L59.9,1.6z"/>
                            <path id="Path_8234" class="st0" d="M77.6,1.6h9.9v1.9h-7.7v5.4h7v2h-7v3.8c0,1.8,0.9,2.7,2.4,2.7h5.3v1.9h-5.2     c-3.1,0-4.8-1.5-4.8-4.8L77.6,1.6z"/>
                            <path id="Path_8235" class="st0" d="M91.2,1.6h5.7c3.7,0,5.3,1.6,5.3,5.3c0.2,2.2-1.1,4.3-3.2,5c1.9,2,3.4,4.4,4.3,7.1v0.2h-2.3     c-0.9-2.5-2.3-4.7-4.1-6.6h-3.5v6.6h-2.2L91.2,1.6z M96.4,10.7c2.8,0,3.7-1.6,3.7-3.8c0-2.6-0.9-3.4-3.3-3.4h-3.3v7.2L96.4,10.7z     "/>
                        </g>
                        <g id="Group_2752" transform="translate(110.733)">
                            <path id="Path_8236" class="st0" d="M1.7,17h0.2c1.4,0.4,2.9,0.7,4.4,0.7H7c2.2,0,3.7-0.8,3.7-3c0-1.9-1-2.5-2.6-2.9l-2.4-0.6     C4,10.6,1.9,9.5,1.9,6.4c0-2.8,2.2-4.8,5.7-4.8h0.5c1.4,0,2.7,0.3,4,0.7v1.9H12c-1.3-0.4-2.5-0.6-3.9-0.7H7.6     c-2.2,0-3.5,0.8-3.5,2.8c0,1.8,1.2,2.3,2.6,2.8l2.3,0.7c1.8,0.5,4,1.5,4,4.9c0,3-2.2,5-5.9,5H6.3c-1.6-0.1-3.1-0.3-4.6-0.7     L1.7,17z"/>
                            <path id="Path_8237" class="st0" d="M15.6,9.5c0-5.2,3.3-7.9,8.5-7.9c1.4,0,2.8,0.3,4,0.8v4h-2V3.8c-0.8-0.2-1.6-0.3-2.4-0.3     c-3.3,0-5.9,1.7-5.9,6v2.2c0,3.9,2,6,5.5,6h1.1c1.2,0,2.4-0.3,3.5-0.7h0.2v1.8c-1.2,0.5-2.4,0.8-3.7,0.8h-1.1     c-5.3,0-7.7-3.3-7.7-7.9L15.6,9.5z"/>
                            <path id="Path_8238" class="st0" d="M31.7,1.8h2.2v7.4h8.7V1.8h2.2v17.6h-2.2v-8.4h-8.7v8.4h-2.2V1.8z"/>
                            <path id="Path_8239" class="st0" d="M48.4,9.9c0-5.2,2.6-8.3,7.4-8.3s7.3,2.9,7.3,8.3v1.5c0,5.2-2.6,8.3-7.3,8.3     s-7.4-2.9-7.4-8.3V9.9z M55.8,17.7c3.4,0,5.2-1.9,5.2-6.4V9.9c0-4.4-1.7-6.4-5.2-6.4s-5.2,1.9-5.2,6.4v1.5     C50.7,15.8,52.4,17.7,55.8,17.7L55.8,17.7z"/>
                            <path id="Path_8240" class="st0" d="M66.1,9.9c0-5.2,2.6-8.3,7.4-8.3s7.3,2.9,7.3,8.3v1.5c0,5.2-2.6,8.3-7.3,8.3     s-7.4-2.9-7.4-8.3V9.9z M73.5,17.7c3.4,0,5.2-1.9,5.2-6.4V9.9c0-4.4-1.7-6.4-5.2-6.4s-5.2,1.9-5.2,6.4v1.5     C68.3,15.8,70,17.7,73.5,17.7z"/>
                            <path id="Path_8241" class="st0" d="M84.5,1.8h2.2v13.1c0,1.8,0.9,2.7,2.4,2.7h5v1.9h-4.8c-3.1,0-4.8-1.6-4.8-4.8V1.8z"/>
                        </g>
                    </g>
                    <g id="Group_2754" transform="translate(127.107 247.587)">
                        <path id="Path_8242" class="st0" d="M18.8,1.6v28.3H27V1.6H18.8z"/>
                        <path id="Path_8243" class="st0" d="M1.7,15.7v14.1h8.2V1.6H1.7V15.7z"/>
                    </g>
                    <path id="Path_8244" class="st1" d="M115.9,259.3l24.7,3.3l0.7-2.1l0.7,2.1h2.2l-1.8,1.3l0.7,2.1l-1.8-1.3L130.3,277l11.1-8.1   l7.7,5.7l-2.8-9.4l2.5-1.9l4-3.1l1.1-0.9h-9.4l-3-9.7l-3,9.7L115.9,259.3z"/>
                </g>
                </svg>
                </div>
                <div class="row justify-content-between text-start ms-2 mb-0">
                <p class="col-6 text-item mb-0 pb-0 pt-3"><span class="fw-bolder fs-5">Date:</span>&nbsp;&nbsp;&nbsp;<span class="fs-5">${DateFormat(lastTrans.date)}</span></p>
                <div class="col-4 text-center me-3 mb-0">
                    <img class="m-0" src="https://api.qrserver.com/v1/create-qr-code/?data=${lastTrans.id}&amp;size=60x60" alt="" title="" />
                </div>
                </div>

                <table class="ms-3">
                    <tbody>
                    <tr>
                        <td  class="text-left">
                            <span class="fw-bolder fs-5">Cash received from:&nbsp;&nbsp;&nbsp;</span> 
                        </td>
                        <td  class="text-left">
                            <span class="fs-5">${whoPay}</span>
                        </td>
                    </tr>
                    <tr>
                        <td  class="text-left">
                            <span class="fw-bolder fs-5">Parent/Guardian of:</span>
                        </td>
                        <td class="text-left">
                            <span class="fs-5">${lastTrans.studentname}</span>
                        </td>
                    </tr>

                    <tr>
                        <td class="text-left">
                            <span class="fw-bolder fs-5">Grade:</span>
                        </td>
                        <td class="text-left">
                            <span class="fs-5">${lastTrans.studentgrade}</span>
                        </td>
                    </tr>

                    <tr>
                        <td class="text-left">
                            <span class="fw-bolder fs-5">For business:</span>
                        </td>
                        <td class="text-left">
                            <span class="fs-5">${lastTrans.servicename}</span>
                        </td>
                    </tr>

                    <tr>
                        <td class="text-left">
                            <span class="fw-bolder fs-5">Payment Method:</span>
                        </td>
                        <td class="text-left">
                            <span class="fs-5">${lastTrans.paymentmethod}</span>
                        </td>
                    </tr>

                    </tbody>
                </table>

                <div class="row justify-content-center p-0 px-0 mx-auto ms-3 amdesc">
                <div class="col-4 cont-left p-0">
                    <div class="col-12 w-100 m-0 p-0 t-head h-table fs-5">Amount</div>
                    <div class="col-12 w-100 m-0 amount-detail p-3 det1 fs-5">${lastTrans.amount}</div>
                </div>
                <div class="col-8 cont-right p-0">
                    <div class="col-12 w-100 m-0 p-0 t-head h-table fs-5">Description</div>
                    <div class="col-12 w-100 m-0 amount-description p-3 det2 fs-5"><p>${lastTrans.details}</p></div>
                </div>
                </div>
                <div class="row text-start ms-2 pt-4">
                <p class="text-item mb-1"><span class="fw-bolder fs-5">Signature:</span> ________________________________</p>
                </div>
            </div>
            <script src="https://ivyis.org/js/bootstrap.bundle.min.js" ></script>
            </body>
        </html>`;
    PrintElem(printDemoHTML);

  });
}

export function formatDateTime(date, format) {
  const padZero = (num) => (num < 10 ? '0' + num : num);

  const year = date.getFullYear();
  const month = padZero(date.getMonth() + 1);  // Months are 0-based
  const day = padZero(date.getDate());

  const hours = padZero(date.getHours());
  const minutes = padZero(date.getMinutes());
  const seconds = padZero(date.getSeconds());

  if (format === "yyyy-MM-dd") {
    return `${year}-${month}-${day}`;
  } else if (format === "yyyy-MM-dd H:i:s") {
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  } else {
    throw new Error("Unsupported format. Use 'yyyy-MM-dd' or 'yyyy-MM-dd H:i:s'");
  }
}
