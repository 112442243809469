import React, { useEffect, useState } from "react";
import { Card, CardBody } from "reactstrap";
import Swal from "sweetalert2";

import { useParams } from "react-router-dom";
import "./intdet.css";
import { Translate } from "../../../helpers/Translate";
import { wrapper_post } from "../../../helpers/call_wrapper_funcs";

const InterventionDetails: React.FC = () => {
  let t = new Translate();
  const { id } = useParams<{ id?: string }>(),
    [intervention, setIntervention] = useState({
      id: "",
      description: "",
      offence: "",
      fullname: "",
      student_id: "",
      recommendation: "",
    }),
    [comments, setComments] = useState([
      {
        id: "",
        commentator: "",
        commentator_id: "",
        comment: "",
      },
    ]),
    [comment, setComment] = useState(""),
    [user, setUser] = useState({
      id: "",
      displayname: "",
      driveid: "",
    });

  useEffect(() => {
    try {
      console.log(id);
      wrapper_post(`${process.env.REACT_APP_BASEURL}student/getintervention.php`, {
        intId: id,
      })
        .then((res: any) => {
          console.log(res.data.data);
          setIntervention(res.data.data);
        });

      wrapper_post(`${process.env.REACT_APP_BASEURL}users/getintcomments.php`, {
        intId: id,
      })
        .then((res: any) => {
          console.log(res.data.data);
          setUser(res.data.data.user);
          setComments(res.data.data.comments);
        });
    } catch {
      Swal.fire("Error while grapping interventions!", "", "error");
    }
  }, []);

  const sectionContent = (html: any) => {
    return <div dangerouslySetInnerHTML={{ __html: html }}></div>;
  };

  const handleCommentChange = (e: any) => {
    console.log(e.target.value);
    setComment(e.target.value);
  };

  const handleSubmit = (e: React.MouseEvent) => {
    e.preventDefault();
    try {
      wrapper_post(`${process.env.REACT_APP_BASEURL}users/interventioncomment.php`, {
        comment: comment,
        intId: id,
      })
        .then((res: any) => {
          Swal.fire("Comment Added Successfully", "", "success");
          setComment("");
          setComments(res.data.data);
        });
    } catch {
      Swal.fire("Error while sending your comment", "", "error");
    }
  };

  return (
    <>
      <div className="header mt-5">
        <h3 className="title">
          {t.translate("Intervention")} {intervention.id}
        </h3>
      </div>

      <div className="row justify-content-center w-100 mx-0 my-5 linkssec">
        <Card>
          <CardBody className="bg-light container-fluid">
            <div>
              <div>
                <h6 className="fw-bold fs-5">
                  {t.translate("Student")}: &nbsp;
                  <span className="fs-6 fw-bold text-danger">
                    {intervention.fullname} : {intervention.student_id}
                  </span>
                </h6>
                <h6 className="fw-bold fs-5">
                  {t.translate("Offence")} : &nbsp;
                  <span className="fs-6 fw-bold text-danger">
                    {intervention.offence}
                  </span>
                </h6>
                <h6 className="my-3 fw-bold fs-5">
                  {t.translate("Recommendation")}:
                </h6>
                <p className="fs-6 fw-bold ms-3 text-black">
                  {sectionContent(intervention.recommendation)}
                </p>
              </div>
            </div>
            <div>
              <div>
                <h6 className="my-3 fw-bold fs-5">
                  {" "}
                  {t.translate("Description")}:
                </h6>
                <p className="fs-6 fw-bold ms-3 text-black">
                  {sectionContent(intervention.description)}
                </p>
              </div>
            </div>
            <hr />
            <div>
              <div>
                <h6 className="fw-bold fs-5">{t.translate("Comments")}: </h6>
              </div>
              <hr />
              <div className="mb-5">
                {comments.map((comment: any, key: any) => {
                  if (comment.user_id == user.id) {
                    return (
                      <>
                        <div className="row justify-content-start text-left align-items-start mb-3">
                          <div className="col-12">
                            {user.driveid ? (
                              <img
                                className="d-inline-block responsive rounded rounded-circle"
                                style={{ width: "80px", height: "50px" }}
                                src={user.driveid?.includes("https://") ? user.driveid : `https://drive.google.com/uc?id=${user.driveid}&export=download`}
                                alt="user-image"
                              />
                            ) : (
                              <img
                                className="d-inline-block responsive"
                                style={{ width: "80px", height: "50px" }}
                                src="https://img.favpng.com/25/13/19/samsung-galaxy-a8-a8-user-login-telephone-avatar-png-favpng-dqKEPfX7hPbc6SMVUCteANKwj.jpg"
                              />
                            )}
                            <h6 className="d-inline-block mx-2 fw-bold">
                              {user.displayname}{" "}
                              <small className="ps-2">{comment.date}</small>
                            </h6>
                          </div>
                          <div className="col-12 mt-3 pt-4 ps-5">
                            <p>{comment.comment}</p>
                          </div>
                        </div>
                        <hr className="w-100" />
                      </>
                    );
                  } else {
                    return (
                      <>
                        <div className="row justify-content-end text-right align-items-end mb-3">
                          <div className="col-12">
                            <h6 className="d-inline-block mx-2 fw-bold">
                              <small className="pr-2">{comment.date}</small>{" "}
                              {comment.username
                                ? comment.username
                                : "Name of User"}
                            </h6>
                            {comment.driveid ? (
                              <img
                                className="d-inline-block responsive rounded rounded-circle"
                                style={{ width: "80px", height: "50px" }}
                                src={comment.udid?.includes("https://") ? comment.udid : `https://drive.google.com/uc?id=${comment.udid}&export=download`}
                                alt="user-image"
                              />
                            ) : (
                              <img
                                className="d-inline-block responsive"
                                style={{ width: "80px", height: "50px" }}
                                src="https://img.favpng.com/25/13/19/samsung-galaxy-a8-a8-user-login-telephone-avatar-png-favpng-dqKEPfX7hPbc6SMVUCteANKwj.jpg"
                              />
                            )}
                          </div>
                          <div className="col-12 mt-3 pt-4 pr-5">
                            <p>{comment.comment}</p>
                          </div>
                        </div>
                        <hr className="w-100" />
                      </>
                    );
                  }
                })}
              </div>
            </div>
            <div>
              <div className="mt-5 text-center">
                <textarea
                  className="w-100 p-2 comment"
                  value={comment}
                  onChange={(e) => handleCommentChange(e)}
                  placeholder="Type your comment....."
                ></textarea>
                <button
                  className="btn btn-primary fs-6 fw-bold w-50 mt-3 mx-auto"
                  onClick={(e) => handleSubmit(e)}
                >
                  {t.translate("Submit")}
                </button>
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    </>
  );
};

export default InterventionDetails;
