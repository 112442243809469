import React, { useEffect, useState, useRef } from "react";
import MDBDataTableV5 from "../../CustomDataTable";
import { Spinner, Card, CardBody, CardHeader } from "reactstrap";
import Swal from "sweetalert2";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import moment from "moment";

import {
  ModalBody,
  Modal,
  ModalHeader,
  ModalFooter,
  Form,
  Input,
  FormGroup,
} from "reactstrap";
import { Translate } from "../../../helpers/Translate";

import Select from "react-select";
import "./LateArrival.css";
import Signature from "../../MyComponents/E_Signture";
import { useSelector } from "react-redux";
import { wrapper_post } from "../../../helpers/call_wrapper_funcs";

interface IFormData {
  id?: string;
  parent?: string;
  students?: Array<string>;
  reason?: string;
  attachments: Array<any>;
  student_id?: string;
  parentSign: string;
  datetime: string;
  relativename: string;
  nationalId: string;
}

const LateArrival: React.FC = () => {
  let t = new Translate();
  const guardian = useSelector((state: any) => state.Parent.guardian);
  const [intDataTable, setIntDataTable] = useState({
    columns: [
      {
        label: "#",
        field: "id",
      },
      {
        label: t.translate("Student Name"),
        field: "stdid",
      },
      {
        label: t.translate("Guardian"),
        field: "guardian",
      },
      {
        label: t.translate("Time"),
        field: "time",
      },
      {
        label: t.translate("Reason"),
        field: "reason",
      },
      {
        label: t.translate("Actions"),
        field: "actions",
      },
    ],
    rows: [
      {
        id: <Spinner color="info" />,
        stdids: <Spinner color="info" />,
        subject: <Spinner color="info" />,
        type: <Spinner color="info" />,
        teacher: <Spinner color="info" />,
        location: <Spinner color="info" />,
        status: <Spinner color="info" />,
        actions: <></>,
      },
    ],
  });
  var now = new Date();
  now.setDate(now.getDate() + 1);
  now.setHours(10);
  now.setMinutes(0);
  now.setMilliseconds(0);
  const sigCanvas = useRef<any>({});
  const [count, setCount] = useState(0);
  const [modal, setModal] = useState(false);
  const [view, setView] = useState(false);
  const [add, setAdd] = useState(false);
  const [latearrivals, setDissmissals] = useState<any>([]);
  const [chosenStudents, setChosenStudents] = useState<any>([]);
  const [students, setStudents] = useState<any>([]);
  const [studentsOptions, setStudentsOptions] = useState<any>([]);
  const [parents, setParents] = useState<any>([]);
  const [img, setImg] = useState(" ");
  const [file, setFile] = useState<any>(null);
  const [formData, setFormData] = useState<IFormData>({
    id: "0",
    parent: guardian.guardian_id,
    students: [],
    attachments: [],
    reason: "",
    parentSign: "",
    datetime: "",
    relativename: "",
    nationalId: "",
  });

  const handleOpen = () => {
    setFormData((prevstate: any) => ({
      ...prevstate,
      id: "0",
      parent: guardian.guardian_id,
      students: [],
      attachments: [],
      reason: "",
      parentSign: "",
    }));
    setModal(true);
  };
  console.log(guardian);
  useEffect(() => {
    try {
      wrapper_post(
        `${process.env.REACT_APP_BASEURL}student/latearrival/getlatearrivals.php`,
        { id: guardian.id }
      )
        .then((res: any) => {
          //get student options
          const newstdOptions: { value: string; label: string }[] =
            res.data.data?.students?.map((std: any) => {
              return { value: std.id, label: `${std.fullname} - ${std.id}` };
            });
          setStudentsOptions(newstdOptions);
          setIntDataTable((prevState) => {
            const newState = { ...prevState };
            let ser = 1;
            let newRows = res.data.data?.latearrivals.map((i: any, key: any) => {
              return {
                ...i,
                id: ser++,
                stdid: newstdOptions?.find(
                  (student: any) => student.value === i.student_id
                )?.label,
                guardian: guardian?.firstname + " " + guardian?.lastname,
                time: i.datetime,
                teacher: i.fullname,
                reason: i.reason,
                actions: (
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      setView(true);
                      ViewModal(i.id);
                    }}
                  >
                    {t.translate("View")}
                  </button>
                ),
              };
            });
            return { ...newState, rows: newRows };
          });
        });
    } catch {
      Swal.fire("Error while grapping interventions!", "", "error")
        .then(() => {
          setFormData((prevstate: any) => ({
            ...prevstate,
            id: "0",
            parent: guardian.guardian_id,
            students: [],
            attachments: [],
            reason: "",
            parentSign: "",
          }));
        })
        .finally(() => {
          setModal(false);
        });
    }
  }, [add]);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    Swal.showLoading();
    const lateArrivalData = new FormData();
    Object.entries(formData).forEach(([key, value]) =>
      key === "attachments" && Array.isArray(value)
        ? value.forEach((attachment) => {
          lateArrivalData.append(attachment.id, attachment.file);
        })
        : key === "students" && Array.isArray(value)
          ? value.forEach((attachment, index) => {
            lateArrivalData.append(`students-${index}`, attachment.value);
          })
          : lateArrivalData.append(key, value)
    );

    const res = wrapper_post(
        `${process.env.REACT_APP_BASEURL}/student/latearrival/addlatearrival.php`,
        lateArrivalData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        clearState();
        setCount(0);
        Swal.fire("Saved Successfully", "", "success").then(() => {
          setModal(false);
          setAdd(!add);
        });
      })
      .catch((e) => {
        let ee = e;
        Swal.fire(ee?.response?.data.msg || "Failed to upload", "", "error");
      });
  };

  const handleAddModal = async () => {
    clearState();
    setModal(true);
    setView(false);
  };

  const clearState = () => {
    setFormData({
      id: "0",
      parent: guardian.guardian_id,
      students: [],
      reason: "",
      attachments: [],
      student_id: "",
      parentSign: "",
      datetime: "",
      relativename: "",
      nationalId: "",
    });
  };

  const handleStudentstSelect = (selectedOption: any) => {
    if (selectedOption) {
      setFormData((prevState) => ({
        ...prevState,
        students: selectedOption,
        student_id: selectedOption.value,
      }));
    } else {
      setFormData((prevState) => ({ ...prevState, student_id: "" }));
    }
  };
  const today = moment().subtract(1, "day");
  const disablePastDt = (current: any) => {
    return current.isAfter(today);
  };

  const save = () => {
    setFormData((prevstate: any) => ({
      ...prevstate,
      parentSign: sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"),
    }));
  };
  const clearSignturePad = (e: any) => {
    e.preventDefault();
    sigCanvas.current.clear();
  };

  const ViewModal = (id: any) => {
    Swal.showLoading();
    wrapper_post(
        `${process.env.REACT_APP_BASEURL}/student/latearrival/getlatearrival.php`,
        { id: id }
      )
      .then((res: any) => {
        setFormData((prevState) => ({
          ...prevState,
          id: res.data.data.latearrival?.id,
          parent: res.data.data.latearrival?.parent_id,
          students: [],
          reason: res.data.data.latearrival?.reason,
          student_id: res.data.data.latearrival?.student_id,
          datetime: res.data.data.latearrival?.datetime,
          attachments: res.data.data.attachments,
          parentSign: res.data.data.latearrival?.parentsignature,

        }));
        setImg(res.data.data.latearrival.parentsignature);
        setView(true);
        setModal(true);
      })
      .finally(() => {
        Swal.close();
      });
  };
  console.log(formData, "formData");
  //   const addAttachement = (e: any) => {
  //     e.preventDefault();
  //     formData.attachments.push({ id: `attachment-${count}`, file: {} });
  //     setCount(count + 1);
  //   };
  //   const handleRemoveattachment = (id: any) => {
  //     let index = formData.attachments.findIndex((a: any) => a.id === id);

  //     formData.attachments.splice(index, 1);
  //     setFormData((prevState) => ({
  //       ...prevState,
  //       attachements: formData.attachments,
  //     }));
  //   };
  //   const handleattachmentdelete = (id: any) => {
  //     Swal.showLoading();
  //     wrapper_post(`${process.env.REACT_APP_BASEURL}/excuse/deleteattachment.php`, {
  //         id: id,
  //       })
  //       .then(() => {
  //         Swal.close();
  //         handleRemoveattachment(id);
  //       });
  //   };
  //   const handleChangeAttachement = (
  //     e: React.ChangeEvent<HTMLInputElement>,
  //     id: any
  //   ) => {
  //     const name = e.target.name;
  //     const value = e.target.value;
  //     let attachements = [...formData.attachments];
  //     let index = attachements.findIndex((a: any) => a.id === id);
  //     if ("file" == name) {
  //       if (e.target.files === null) return;
  //       const selectedFile = e.target.files[0];
  //       setFile(selectedFile);
  //       attachements[index].file = selectedFile;
  //     }
  //     setFormData((prevState) => ({ ...prevState, attachments: attachements }));
  //   };
  //   console.log(formData.attachments);
  const handleChange = (e: any) => {
    var name: any = "datetime";
    var value: any = "";

    if (e._isAMomentObject || e.target === null || e.target === undefined) {
      if (e._isAMomentObject) {
        value = e.format();
      } else {
        value = new Date().toISOString();
      }
    } else {
      name = e.target.name;
      value = e.target.value;
    }
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };
  //   console.log("students", students);
  return (
    <>
      <div className="row justify-content-center w-100 mx-0 my-5 linkssec">
        <Card>
          <CardHeader>{t.translate("Late Arrival")}</CardHeader>
          <hr />
          <CardBody className="bg-light">
            <button className="btn btn-primary my-3" onClick={handleAddModal}>
              {t.translate("Add Request")}
            </button>
            <MDBDataTableV5
              hover
              responsive
              borderless={false}
              bordered
              striped
              entriesOptions={[5, 20, 25]}
              entries={5}
              pagesAmount={4}
              data={intDataTable}
              fullPagination
              searchTop
              searchBottom={false}
            />
          </CardBody>
        </Card>
      </div>

      <Modal className="sizeModal" isOpen={modal}>
        <ModalHeader> {t.translate("Add Late Arrival")}</ModalHeader>
        <ModalBody>
          <Form onSubmit={handleSubmit}>
            <label style={{ marginBottom: "2%", marginTop: "2%" }}>
              {t.translate("Select Student")}
            </label>

            <Select
              isClearable
              value={
                studentsOptions?.find(
                  (f: any) => f.value === formData?.student_id
                ) ?? ""
              }
              options={studentsOptions}
              onChange={handleStudentstSelect}
              menuPortalTarget={document.body}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            />

            <label style={{ marginBottom: "2%", marginTop: "2%" }}>
              {t.translate("Reason")}
            </label>
            <textarea
              className="form-control"
              rows={5}
              name="reason"
              value={formData?.reason}
              onChange={(e: any) => {
                handleChange(e);
              }}
            ></textarea>



            <div style={{ width: "100%" }}>
              <label>{t.translate("Pick a Date and Time")}</label>
              <Datetime
                value={new Date(formData.datetime)}
                timeConstraints={{
                  hours: { min: 8, max: 16, step: 1 },
                  minutes: { min: 0, max: 59, step: 1 },
                  seconds: { min: 0, max: 59, step: 1 },
                }}
                initialValue={now}
                isValidDate={disablePastDt}
                utc
                dateFormat="MMMM Do YYYY"
                onChange={(e: any) => handleChange(e)}
              />
            </div>

            <label style={{ marginBottom: "2%", marginTop: "2%" }}>
              {t.translate("Signature")}
            </label>

            {view ? (
              <img
                style={{ width: "100%", border: "2px solid" }}
                src={img ?? ""}
              />
            ) : (
              <div>
                <Signature
                  reference={sigCanvas}
                  onEnd={save}
                  clear={(e: any) => {
                    clearSignturePad(e);
                  }}
                />
              </div>
            )}

            <Input
              type="submit"
              disabled={

                formData.student_id === "" ||
                formData.parent === "" ||
                formData.parentSign == "" ||
                formData.reason == ""
                || formData.datetime == ""
              }
              className="btn btn-primary mt-3"
              style={{ backgroundColor: "blue" }}
              value={t.translate("Submit")}
            />
          </Form>
        </ModalBody>
        <ModalFooter>
          <button className="btn btn-secondary" onClick={() => setModal(false)}>
            {t.translate("Cancel")}
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default LateArrival;