// src/indexedDB.js
export const openDB = () => {
    return new Promise((resolve, reject) => {
        const request = indexedDB.open('ivyDatabase', 1);

        request.onupgradeneeded = (event: any) => {
            const db = event.target.result;
            db.createObjectStore('ivyStore', { keyPath: 'id' }); // Use in-line keys
        };

        request.onsuccess = (event: any) => {
            resolve(event.target.result);
        };

        request.onerror = (event: any) => {
            reject(event.target.errorCode);
        };
    });
};


export const getAllItems = (db: any) => {
    return new Promise((resolve, reject) => {
        const transaction = db.transaction('ivyStore', 'readonly');
        const store = transaction.objectStore('ivyStore');
        const request = store.getAll();

        request.onsuccess = () => {
            resolve(request.result);
        };

        request.onerror = (event: any) => {
            reject(event.target.errorCode);
        };
    });
};


export const getItem = (db: any, id: any) => {
    return new Promise((resolve, reject) => {
        const transaction = db.transaction('ivyStore', 'readonly');
        const store = transaction.objectStore('ivyStore');
        const request = store.get(id);

        request.onsuccess = () => {
            resolve(request.result);
        };

        request.onerror = (event: any) => {
            reject(event.target.errorCode);
        };
    });
};

// export const getItemPost = (db: any, id: any, paramsjson: any,configjson: any) => {
//     return new Promise((resolve, reject) => {
//         const transaction = db.transaction('ivyStore', 'readonly');
//         const store = transaction.objectStore('ivyStore');
//         // const request = store.get(id);
//         // let query = index.get(["Alice", 25]);
//         const index = store.index("paramsjson_configjson");
//         const request = index.get([id, paramsjson,configjson]);

//         request.onsuccess = () => {
//             resolve(request.result);
//         };

//         request.onerror = (event: any) => {
//             reject(event.target.errorCode);
//         };
//     });
// };

export const deleteItem = (db: any, id: any) => {
    const transaction = db.transaction('ivyStore', 'readwrite');
    const store = transaction.objectStore('ivyStore');
    const deleteRequest = store.delete(id);

    // deleteRequest.onsuccess = () => {
    //     console.log('Data deleted successfully');
    // };

    // deleteRequest.onerror = (event: any) => {
    //     console.error('Error deleting data:', event.target.errorCode);
    // };
};

export const addItem = (db: any, item: any) => {
    const transaction = db.transaction('ivyStore', 'readwrite');
    const store = transaction.objectStore('ivyStore');
    const deleteRequest = store.delete(item.id);
    deleteRequest.onsuccess = () => {
        store.add(item);
        console.log("item");
        console.log(item);
    };

    deleteRequest.onerror = (event: any) => {
        store.add(item);
        console.log("item");
        console.log(item);
    };
};

