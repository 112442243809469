import React, { Dispatch, useEffect, useState } from 'react';
import { Row, Col, ModalBody, ModalFooter, Modal, ModalHeader, Form, Input, FormGroup } from 'reactstrap';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { actionCreators as StudnetCreator } from "../../../store/student";

import Swal from 'sweetalert2';
import { url } from '../../../store/initialStatus';

import './intervention.css'

import { Translate } from '../../../helpers/Translate';
import { wrapper_post } from '../../../helpers/call_wrapper_funcs';



const InterventionModal: React.FC<any> = (props: any) => {
  const dispatch: Dispatch<any> = useDispatch();
  const students = useSelector((state: any) => state.Student.students);

  const guardian = useSelector((state: any) => state.Parent.guardian);
  const studentsOptions: any = [];


  const [teachersOptions, setTeachersOptions] = useState<any>([]);
  const [courses, setCourses] = useState<any>([]);
  const [teachers, setTeachers] = useState<any>([]);
  const [coursesOptions, setCoursesOptions] = useState<any>([]);



  students?.forEach((student: any) => {
    studentsOptions.push({ value: student.id, label: student.fullname })
  });

  useEffect(() => {
    dispatch(StudnetCreator.GetStudents({ id: guardian.id }))
    dispatch(StudnetCreator.GetExcusesReasons())
  }, [])
  const [formData, setFormData] = useState({
    id: 0,
    student_id: "",
    course_id: "",
    teacher_id: "",
    lesson: "",
    notes: "",
    date: '',
    description: "",
    status: "",


  });


  useEffect(() => {

    if (props.interventionId != 0) {


      try {
        wrapper_post(`${process.env.REACT_APP_BASEURL}student/academicinterventions/getintervention.php`, { id: props.interventionId })
          .then((res: any) => {
            setFormData({ ...res.data.data.intervention });
            setTeachers(res.data.data.teachers);
            setCourses(res.data.data.courses);
            const tempTeachers: any = [];
            res.data.data?.teachers?.forEach((teacher: any) => {
              tempTeachers.push({ value: teacher.teacherid, label: `${teacher.teachername}  - ${teacher.subject === null ? "" : teacher.subject}` })
            });
            setTeachersOptions(tempTeachers);

            const teacher = res.data.data.teachers?.find((t: any) => t.teacherid == res.data.data.intervention.teacher_id);
            const tempcourses: any = [];
            res.data.data.courses?.forEach((course: any) => {

              if (teacher.subject_id == course.subject_id) {

                tempcourses.push({ value: course.id, label: course.name })
              }
            });
            setCoursesOptions(tempcourses)
            props.setModal(true)

          });
      } catch {
        Swal.fire("Error while grapping AcademicInterventionss!", "", "error");
      }
    }

  }, [props.interventionId])



  const intialFormData = () => {
    setFormData({
      id: 0,
      student_id: "",
      course_id: "",
      teacher_id: "",
      lesson: "",
      notes: "",
      date: '',
      description: "",
      status: "",

    });



  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;
    setFormData(prevState => ({ ...prevState, [name]: value }));
  };

  const handleSelectStudent = (e: any) => {
    setFormData(prevState => ({ ...prevState, student_id: e.value }));

    try {
      wrapper_post(`${process.env.REACT_APP_BASEURL}student/academicinterventions/getstudentinfo.php`, { student_id: e.value })
        .then((res: any) => {
          const tempTeachers: any = [];
          res.data.data?.teachers?.forEach((teacher: any) => {
            tempTeachers.push({ value: teacher.teacherid, label: `${teacher.teachername}  - ${teacher.subject === null ? "" : teacher.subject}` })
          });
          setTeachersOptions(tempTeachers);
          setCourses(res.data.data.courses);
          setTeachers(res.data.data.teachers);


        });
    } catch {
      Swal.fire("Error while grapping Academic Interventionss!", "", "error");
    }
  }

  const handleSelectCourse = (e: any) => {
    setFormData(prevState => ({ ...prevState, course_id: e.value }));
  }

  const handleSelectTeacher = (e: any) => {
    const tempcourses: any = [];
    const teacher = teachers?.find((t: any) => t.teacherid == e.value);

    courses?.forEach((course: any) => {

      if (teacher.subject_id == course.subject_id) {

        tempcourses.push({ value: course.id, label: course.name })
      }
    });
    setCoursesOptions(tempcourses)
    setFormData(prevState => ({ ...prevState, teacher_id: e.value }));
  }



  const submitHandler = (e: any) => {

    e.preventDefault();

    if (!formData.student_id || !formData.lesson || !formData.teacher_id || !formData.course_id) {
      Swal.fire('Please Select all fields. ', "", 'warning')
      return;
    }

    Swal.fire({
      title: 'Please confirm your request. ',
      showDenyButton: true,
      confirmButtonText: `I confirm`,
      denyButtonText: `Cancel`,
      icon: 'warning',


    }).then((e) => {
      if (e.isConfirmed) {


        Swal.fire({
          html: '<div style="color:blue;"> Loading....... </div>',
          showConfirmButton: false

        })

        wrapper_post(`${url}student/academicinterventions/addintervention.php`, formData).then((res: any) => {

          console.log(res.data.data)
          if (res.data.data === "success") {
            Swal.fire("Sent Successfully", "", "success").then(() => {
              Swal.close();
              props.setModal(false);
              intialFormData();
              props.setP(!props.p)

            });
          } else {
            Swal.close();
            props.setModal(false);
            intialFormData();
          }

        }).catch(() => {
          Swal.close();
        })


      }
    })



  }



  const t = new Translate()
  return (
    <>
      <Modal isOpen={props.modal} className="sizeModal" >
        <ModalHeader>{t.translate("Request")} {t.translate("academic intervention")} :</ModalHeader>
        <ModalBody>
          <Form onSubmit={submitHandler}>


            <FormGroup>


              {formData.student_id && formData.date && formData.lesson && formData.teacher_id && formData.course_id ?
                null : (
                  <Row className="mb-3 text-center" style={{ color: "red" }}>
                    <Col md={12}>
                      <div>
                        {t.translate("Please fill all fields")}
                      </div>
                    </Col>
                  </Row>
                )
              }

              <Row >
                <Col md={12}>
                  <div className="form-group">
                    <label htmlFor="emergencyname1">{t.translate("Students")}</label>
                    <Select
                      closeMenuOnSelect={true}
                      options={studentsOptions}
                      onChange={(e) => {
                        handleSelectStudent(e);
                      }}
                      value={studentsOptions.filter((student: any) => student.value == formData.student_id)}
                    />

                  </div>
                </Col>
                <Col md={12}>
                  <div className="form-group">
                    <label htmlFor="teachers">{t.translate("Teachers")}</label>
                    <Select
                      closeMenuOnSelect={true}
                      options={teachersOptions}
                      onChange={(e) => {
                        handleSelectTeacher(e);
                      }}
                      value={teachersOptions?.filter((teacher: any) => teacher.value == formData.teacher_id)}
                    />

                  </div>
                </Col>

                <Col md={12}>
                  <div className="form-group">
                    <label htmlFor="courses">{t.translate("courses")}</label>
                    <Select
                      closeMenuOnSelect={true}
                      options={coursesOptions}
                      onChange={(e) => {
                        handleSelectCourse(e);
                      }}
                      value={coursesOptions?.filter((course: any) => course.value == formData.course_id)}
                    />

                  </div>
                </Col>




                <Col sm={12} >
                  <label>Lesson</label>
                  <Input type="text" onChange={handleChange} rows="2" value={formData.lesson} name="lesson" >
                  </Input>
                </Col>
                <Col sm={12} >
                  <label>Notes</label>
                  <Input type="textarea" onChange={handleChange} rows={4} value={formData.notes} name="notes" >
                  </Input>
                </Col>




              </Row>

            </FormGroup>



            <Row className="justify-content-center" >

              {formData.status != "Approved" ? (
                <Col md={3}>
                  <Input type="submit" className="btn btn-primary mt-3" style={{ backgroundColor: "blue" }}
                    disabled={!formData.student_id || !formData.lesson || !formData.teacher_id || !formData.course_id} value="Submit" />
                </Col>
              ) : null}

              <Col md={3}>
                <Input className="btn btn-danger mt-3" style={{ backgroundColor: "red" }} onClick={() => { props.setModal(false); intialFormData(); }} value="Cancel" />
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
        </ModalFooter>
      </Modal>



    </>
  )
}


export default InterventionModal;