import React, { useState } from 'react';

import { url } from '../../../../store/initialStatus';

import { Row, Col, ModalBody, ModalFooter, Modal, ModalHeader, Form, Input, FormGroup } from 'reactstrap';

import "./Home.css";
import Swal from 'sweetalert2';
  
import { useEffect } from 'react';
import { wrapper_post } from '../../../../helpers/call_wrapper_funcs';


interface formData {
    student_id: string,
    fullname: string,
    diabetes:string ,
    asthma:string ,
    epilpsy:string ,
    other1:boolean ,
    other1text:string ,
   
    hearing:string ,
    vision:string ,
    speech:string ,
    writing:string ,
   
    q3text:string ,
    other3:boolean ,
    q3:string ,
    q4:string ,
    q5:string ,
    q6:string ,
    emergencyname1:string ,
    emergencyrelationship1:string ,
    emergencymobile1:string ,
    emergencyname2:string ,
    emergencyrelationship2:string ,
    emergencymobile2:string ,
  }
  
const MedicalRecord = () =>{

    const styles = {
        display: "block",
        opacity: "1"
     }
    const [modal, setModal] = useState(false);
    const [formData, setFormData] = useState<formData>({
      student_id: "",
      fullname: "",
      diabetes :"",
      asthma : "",
      epilpsy:"",
      hearing :"",
     
      speech :"" ,
      vision :"",
      writing:"",
      other1:false ,
      other1text:"",
      other3:false ,
      q3text :"",
      q3:"",
      q4:"",
      q5:"",
      q6:"",
      emergencymobile1:"",
      emergencymobile2 :"",
      emergencyname1:"",
      emergencyname2:"",
      emergencyrelationship1:"",
      emergencyrelationship2:""
    });

    const clearState = ()=>{
        setFormData({
            student_id: "",
            fullname: "",
            diabetes :"",
            asthma : "",
            epilpsy:"",
            hearing :"",
           
            speech :"" ,
            vision :"",
            writing:"",
            other1:false ,
      other3:false ,

            other1text:"",
            q3text :"",
            q3:"",
            q4:"",
            q5:"",
            q6:"",
            emergencymobile1:"",
            emergencymobile2 :"",
            emergencyname1:"",
            emergencyname2:"",
            emergencyrelationship1:"",
            emergencyrelationship2:""
          })
          setModal(false);
    }

    const getStudent = ()=>{
        wrapper_post(`${url}student/medicalrecord/getstudent.php`).then((res: any) => {
             if (res.data.data.student_id ) {
                setFormData(prevState => ({...prevState,student_id: res.data.data.student_id , fullname: res.data.data.fullname}));
                setModal(true);
             }else{
                
             }
         })
    }
    useEffect(() => {
        (async () => {
           getStudent()
        })();
    }, []);


    const submitHandler = (e:any) => {
      e.preventDefault();
        Swal.showLoading()
        wrapper_post(`${url}student/medicalrecord/savemedicalrecord.php`, formData).then((res: any) => {
            if (res.data.status === "success") {
                Swal.fire("Saved Successfully!", "", "success").then(()=>{
                    clearState()
                    getStudent()
                })
            }else{
                Swal.fire("Something go wrong please try again later !!","" ,"error");
            }
        })
    }
  
    const handleChange = (e:any) => {
      const name = e.target.name;
      const value = e.target.value;
      setFormData(prevState => ({...prevState, [name]: value}));
    }
    return (
        <Modal isOpen={modal}>
        <ModalHeader>Medical record for student : {formData.fullname}</ModalHeader>
        <ModalBody>
          <Form onSubmit={submitHandler}>
            <FormGroup>
            <label>1) Is your child suffering from any genetic diseases?</label>
            <Row >
              <Col md={3} sm={6}>
                <div className="form-check">
                  <input style={styles} className="form-check-input" type="checkbox" name="diabetes" id="diabetes" value="diabetes" checked={formData.diabetes ? true : false} onChange={handleChange}/>
                  <label className="form-check-label" htmlFor="diabetes">
                  Diabetes
                  </label>
                </div>
              </Col>
              <Col md={3} sm={6}>
              <div className="form-check">
                  <input style={styles} className="form-check-input" type="checkbox" name="asthma" id="asthma" value="asthma" checked={formData.asthma ? true : false}  onChange={handleChange} />
                  <label className="form-check-label" htmlFor="asthma">
                  Asthma
                  </label>
                </div>
              </Col>
              <Col md={3} sm={6}>
              <div className="form-check">
                  <input style={styles} className="form-check-input" type="checkbox" name="epilpsy" id="epilpsy" value="epilpsy" checked={formData.epilpsy ? true : false} onChange={handleChange}  />
                  <label className="form-check-label" htmlFor="epilpsy">
                  Epilepsy
                  </label>
                </div>
                  </Col>
              <Col md={3} sm={6}>
                        
                    <div className="form-check">
                        <input style={styles} className="form-check-input" type="checkbox" name="other1" id="other1" value="other1" checked={formData.other1 ? true : false} onChange={(e:any)=> {
                            
                            handleChange(e) ;
                             const temp = e.target.checked ? true : false ;
                            setFormData(prevState => ({...prevState, other1: temp}))
                            
                            
                        }}  />
                        <label className="form-check-label" htmlFor="other1">
                        Other
                        </label>
                        </div>
                  </Col>
                        {formData.other1 ? (  <Col md={6}> 
                        <label className="mt-2"> If other write it here</label>
                        <textarea className="form-control" name = "other1text" rows={3} value={formData.other1text} onChange={handleChange}></textarea>        
                   </Col>) : null}
                


            </Row>
          </FormGroup>



          <FormGroup>
            <label>2) Is your child suffering from any of the following impairment?</label>
            <Row >
              <Col md={3}>
                <div className="form-check">
                  <input style={styles} className="form-check-input" type="checkbox" name="hearing" id="hearing" value="hearing" checked={formData.hearing ? true : false}  onChange={handleChange} />
                  <label className="form-check-label" htmlFor="hearing">
                  Hearing
                  </label>
                </div>
              </Col>
              <Col md={3}>
              <div className="form-check">
                  <input style={styles} className="form-check-input" type="checkbox" name="vision" id="vision" value="vision" checked={formData.vision ? true : false}  onChange={handleChange} />
                  <label className="form-check-label" htmlFor="vision">
                  Vision 
                  </label>
                </div>
              </Col>
              <Col md={3}>
              <div className="form-check">
                  <input style={styles} className="form-check-input" type="checkbox" name="speech" id="speech" value="speech" checked={formData.speech ? true : false}  onChange={handleChange} />
                  <label className="form-check-label" htmlFor="speech">
                  Speech
                  </label>
                </div>
                  </Col>
              <Col md={3}>
                
              <div className="form-check">
                  <input style={styles} className="form-check-input" type="checkbox" name="writing" id="writing" value="writing" checked={formData.writing ? true : false} onChange={handleChange}  />
                  <label className="form-check-label" htmlFor="writing">
                  Writing
                  </label>
                </div>
                  </Col>

            </Row>
          </FormGroup>



          <FormGroup>
            <label>3) Is your child has allergy to medicine or other?</label>
            <Row >
              <Col md={3}>
                <div className="form-check">
                  <input style={styles} className="form-check-input" type="radio" required name="q3" id="yes3" value="yes3" checked={formData.q3 =="yes3" ? true : false}   onChange={(e)=> {
                            handleChange(e) ;
                             setFormData(prevState => ({...prevState,other3: false }))
                        }} />
                  <label className="form-check-label" htmlFor="yes3">
                  Yes
                  </label>
                </div>
              </Col>
              <Col md={3}>
              <div className="form-check">
                  <input style={styles} className="form-check-input" type="radio" required name="q3" id="no3" value="no3" checked={formData.q3 =="no3" ? true : false}   onChange={(e)=> {
                            handleChange(e) ;
                             setFormData(prevState => ({...prevState,other3: false }))
                        }} />
                  <label className="form-check-label" htmlFor="no3">
                  No 
                  </label>
                </div>
              </Col>
              <Col md={3}>
              <div className="form-check">
                  <input style={styles} className="form-check-input" type="radio" required name="q3" id="other3" value="other3" checked={formData.q3 == "other3" ? true : false}   onChange={(e)=> {
                            handleChange(e) ;
                            const temp = e.target.checked ?true : false;
                            setFormData(prevState => ({...prevState,other3: temp }))
                        }} />
                  <label className="form-check-label" htmlFor="other3">
                  Other
                  </label>
                </div>
                  </Col>
            </Row>
 

            {formData.other3 ?  (
                 <Row >
                 <label className="mt-2"> If other write it here</label>
                             <textarea className="form-control" name = "q3text" value={formData.q3text}  rows={3} onChange={handleChange}></textarea>
                 </Row>
            ) : null }
           
          </FormGroup>




          <FormGroup>
            <label>4)Is there any dietary  restrications?</label>
            <Row >
              <Col md={3}>
                <div className="form-check">
                  <input style={styles} className="form-check-input" type="radio" required name="q4" id="yes4" value="yes4" checked={formData.q4 =="yes4" ? true : false} onChange={handleChange} />
                  <label className="form-check-label" htmlFor="yes4">
                  Yes
                  </label>
                </div>
              </Col>
              <Col md={3}>
              <div className="form-check">
                  <input style={styles} className="form-check-input" type="radio" required name="q4" id="no4" value="no4" checked={formData.q4 =="no4" ? true : false}  onChange={handleChange} />
                  <label className="form-check-label" htmlFor="no4">
                  No 
                  </label>
                </div>
              </Col>
            
            </Row>
          </FormGroup>



          
          <FormGroup>
            <label>5)Is there any operations done to the child?</label>
            <Row >
              <Col md={3}>
                <div className="form-check">
                  <input style={styles} className="form-check-input" type="radio" required name="q5" id="yes5" value="yes5" checked={formData.q5 =="yes5" ? true : false}  onChange={handleChange} />
                  <label className="form-check-label" htmlFor="yes5">
                  Yes
                  </label>
                </div>
              </Col>
              <Col md={3}>
              <div className="form-check">
                  <input style={styles} className="form-check-input" type="radio" required name="q5" id="no5" value="no5" checked={formData.q5 =="no5" ? true : false}  onChange={handleChange} />
                  <label className="form-check-label" htmlFor="no5">
                  No 
                  </label>
                </div>
              </Col>
            
            </Row>
          </FormGroup>

          <FormGroup>
            <label>6)Are there any special consendrations/actions required by us at school regarding health issue?</label>
            <Row >
              <Col md={6}>
                <div className="form-check">
                  {/* <input style={styles} className="form-check-input" type="text" name="q6"   onChange={handleChange} /> */}
                  <textarea className="form-control" name = "q6" id="q6" required rows={3} value={formData.q6} onChange={handleChange}> </textarea>
                 
                </div>
              </Col>
             
            
            </Row>
          </FormGroup>

          <FormGroup>
            <label >7) In emergency treatment for the child who become ill/ injured while under school care the number we cantacted</label>
            <br />
            <label htmlFor=""> A) emergency contact 1</label>
            <Row >
              <Col md={4}>
              <div className="form-group">
                <label htmlFor="emergencyname1">Name</label>
                <input type="text" className="form-control" id="emergencyname1" required name="emergencyname1" value={formData.emergencyname1}   placeholder="Enter name"  onChange={handleChange} />
              </div>
              </Col>
              <Col md={4}>
              <div className="form-group">
                <label htmlFor="emergencyrelationship1">Relationship</label>
                <input type="text" className="form-control" id="emergencyrelationship1" required name ="emergencyrelationship1" value={formData.emergencyrelationship1}  placeholder="Enter relationship"  onChange={handleChange} />
              </div>
              </Col>
              <Col md={4}>
              <div className="form-group">
                <label htmlFor="emergencymobile1">Mobile Number</label>
                <input type="text" className="form-control" id="emergencymobile1" required name ="emergencymobile1" placeholder="Enter mobile" value={formData.emergencymobile1}  onChange={handleChange} />
              </div>
              </Col>   
            </Row>
            <label htmlFor=""> B) emergency contact 2</label>
            <Row >
              <Col md={4}>
              <div className="form-group">
                <label htmlFor="emergencyname2">Name</label>
                <input type="text" className="form-control" id="emergencyname2" name="emergencyname2"  value={formData.emergencyname2}  placeholder="Enter name"  onChange={handleChange} />
              </div>
              </Col>
              <Col md={4}>
              <div className="form-group">
                <label htmlFor="emergencyrelationship2">Relationship</label>
                <input type="text" className="form-control" id="emergencyrelationship2" name ="emergencyrelationship2" value={formData.emergencyrelationship2}  placeholder="Enter relationship"  onChange={handleChange} />
              </div>
              </Col>
              <Col md={4}>
              <div className="form-group">
                <label htmlFor="emergencymobile2">Mobile Number</label>
                <input type="text" className="form-control" id="emergencymobile2" name ="emergencymobile2" placeholder="Enter mobile" value={formData.emergencymobile2} onChange={handleChange} />
              </div>
              </Col>   
            </Row>
          </FormGroup>



                <Row className="justify-content-center" > 

               <Col md={3}>  
            <Input type="submit" className="btn btn-primary mt-3" style={{ backgroundColor: "blue" }} value="Submit" />
               
               </Col>
                </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
        </ModalFooter>
      </Modal>

    )
}

        export default MedicalRecord ;