
import { error } from "jquery";
import { addItem, getItem, openDB } from "./indexedDB";
import axios from "axios";
import { json } from "react-router-dom";

export const wrapper_get = (url: string) => {
    return new Promise((resolve, reject) => {
        openDB().then(async (db) => {
            axios.get(url).then((response) => {
                console.log("wrapper_get 1 res");
                console.log(response);
                // record indexeddb
                const newItem = { id: `get ${url}`, data: response?.data?.data ?? null };
                console.log("wrapper_get 2 res");
                console.log(response);
                addItem(db, newItem);
                console.log("wrapper_get 3 res");
                console.log(response);
                resolve(response);
            }).catch((error) => {
                if ((error.code === 'ERR_NETWORK') || (error.code === 'ERR_INTERNET_DISCONNECTED')) {
                    // from indexeddb
                    getItem(db, `get ${url}`).then((res_data_data: any) => {
                        console.log("wrapper_get ERR_NETWORK res");
                        console.log(res_data_data);
                        if (res_data_data) {
                            const res = { data: { data: res_data_data?.data } }
                            resolve(res);
                        } else {
                            reject(error);
                        }
                    });
                } else {
                    // Other errors (e.g., 4xx, 5xx responses)
                    reject(error);
                }
            })
        });
    });
};

export const wrapper_post = (url: string, params: any = {}, config: any = {}) => {

    console.log("url");
    console.log(url);

    console.log("params");
    console.log(params);

    console.log("config");
    console.log(config);
    const paramsjson = JSON.stringify(params);
    const configjson = JSON.stringify(config);
    const id = `post ${url} ${paramsjson} ${configjson}`;
    return new Promise((resolve, reject) => {
        openDB().then(async (db) => {
            axios.post(url, params, config).then((response) => {
                console.log("wrapper_post 1 res");
                console.log(response);
                // record indexeddb
                const newItem = {
                    id,
                    data: response?.data?.data ?? null,
                    // paramsjson,
                    // configjson,
                };
                console.log("wrapper_post 2 res");
                console.log(response);
                addItem(db, newItem);
                console.log("wrapper_post 3 res");
                console.log(response);
                resolve(response);
            }).catch((error) => {
                if ((error.code === 'ERR_NETWORK') || (error.code === 'ERR_INTERNET_DISCONNECTED')) {
                    // from indexeddb
                    getItem(db, id).then((res_data_data: any) => {
                        console.log("wrapper_post ERR_NETWORK res");
                        console.log(res_data_data);
                        if (res_data_data) {
                            const res = { data: { data: res_data_data?.data } }
                            resolve(res);
                        } else {
                            reject(error);
                        }
                    });
                } else {
                    // Other errors (e.g., 4xx, 5xx responses)
                    reject(error);
                }
            })
        });
    });
};
