
  
import { url } from '../store/initialStatus'
import Swal from 'sweetalert2';
import { wrapper_post } from '../helpers/call_wrapper_funcs';

export function PrintElem(content: any, width = 900, height = 500) {
    var mywindow: any = window.open('', 'PRINT', `height=${height},width=${width}`);
    mywindow.document.write(content);
    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/
    mywindow.onafterprint = () => {
        console.log('After print');
        //mywindow.close();
    };
    mywindow.print();

    return true;
}


export const roundNumber = (number: any) => {
    let modifiedNumber: any = number;
    if (number === undefined || number === null || number === "") return "NA"
    if (number % 1 != 0) {
        modifiedNumber = parseFloat(number)?.toFixed(2);
    }

    if (isNaN(modifiedNumber)) {
        return "NA";
    } else {
        return modifiedNumber;
    }
};
export function PrintGrades(id: string, year_id: string) {
    const URL = `${url}student/getstudentreport.php`;
    wrapper_post(URL, { id: id, year_id: year_id }).then((res: any) => {
        if (res.data.data?.errorMsg) {
            Swal.fire("", res.data.data.errorMsg, "warning")
            return
        }

        PrintElem(res.data.data.report);
    });
}

export function DateFormat(date: string) {
    let options: any = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' };
    let d = new Date(date);
    return d.toLocaleString('en-us', options)
}